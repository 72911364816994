import { Pipe, PipeTransform } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ViolationService } from "../services/violation.service";

@Pipe({
  name: 'secure_image'
})
export class SecureImagePipe implements PipeTransform {

  constructor(private http: HttpClient, private violationService: ViolationService) {
  }

  async transform(src: string): Promise<string> {
    if (src.indexOf(';base64,') > -1) {
      return src;
    }
    let accessoryDataById = await this.violationService.getAccessoryDataById(src);
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onloadend = () => resolve(reader.result as string);
      reader.readAsDataURL(accessoryDataById);
    });
  }
}
