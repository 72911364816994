<app-container headerTitle="Login">
  <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" autocomplete="off" class="login__form">
    <div class="row justify-content-md-center">
      <div class="form-group col col-md-6 login">
        <label for="username" class="larger-label mb-1">Username</label>
        <input type="text" id="username" formControlName="username" class="form-control py-4" placeholder="Enter Username"
          [ngClass]="{ 'is-invalid': submitted && form.username.errors }" />
        <div *ngIf="submitted && form.username.errors" class="invalid-feedback">
          <div *ngIf="form.username.errors.required">Username is required</div>
        </div>
      </div>
    </div>
    <div class="row justify-content-md-center">
      <div class="form-group col col-md-6 password">
        <label for="password" class="larger-label mb-1">Password</label>
        <input type="password" id="password" formControlName="password" class="form-control py-4" placeholder="Enter Password"
          [ngClass]="{ 'is-invalid': submitted && form.password.errors }" />
        <div *ngIf="submitted && form.password.errors" class="invalid-feedback">
          <div *ngIf="form.password.errors.required">Password is required</div>
        </div>
      </div>
    </div>
    <div class="row justify-content-md-center ">
      <div class="form-group col col-md-6">
        <button [disabled]="loading" [ngClass]="{'btn btn-submit':true, 'active':!this.loginForm.invalid}">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          Login
        </button>
         <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
      </div>
    </div>
  </form>
</app-container>
