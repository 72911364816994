import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {EmailManagementService} from "../../../../../services/email-management.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-edit-recipient-dialog',
  templateUrl: './edit-recipient-dialog.component.html',
  styleUrls: ['../../../../../app.component.scss','./edit-recipient-dialog.component.css']
})
export class EditRecipientDialogComponent implements OnInit {

  @Input() recipient;
  @Output() resultEvent = new EventEmitter();
  error = '';
  dailyDigestRecipientEditForm: FormGroup;


  constructor(public activeModal: NgbActiveModal, private emailManagementService: EmailManagementService) {
  }

  ngOnInit(): void {
    this.dailyDigestRecipientEditForm = new FormGroup({
      email: new FormControl(this.recipient.email, [Validators.maxLength(255), Validators.required, Validators.pattern('(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))')])
    });
  }

  get form() {
    return this.dailyDigestRecipientEditForm.controls;
  }

  closeAlert() {
    this.error = null;
  }

  onSubmit() {
    const dailyDigestRecipient = {
      id: this.recipient.id,
      email: this.dailyDigestRecipientEditForm.controls.email.value,
      townId: this.recipient.townId
    };
    let formData = new FormData();
    formData.append("recipient", JSON.stringify(dailyDigestRecipient));
    this.emailManagementService.changeEmailForRecipient(formData).subscribe(value => {
      this.activeModal.dismiss();
      this.resultEvent.emit(value);
    }, error => this.error = error);
  }
}
