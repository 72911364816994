<div class="row no-gutters possible-match"> 
    <button type="button" class="btn btn-submit active" [hidden]="fuzzyLogicMatchBlockVisible"
        (click)="showFuzzyLogicMatch()">Possible match found</button>
    <ng-container *ngIf="fuzzyLogicMatchBlockVisible">
            <div class="possible-match__message">
                <p>The license plate you entered is {{parkingStatus.plateNumber | uppercase}}</p>
                <p>This plate was not found in the kiosk data</p>
                <p>{{parkingStatus.fuzzyLogicParkingStatusCandidate.plateNumber}} matches
                    at {{(parkingStatus.fuzzyLogicParkingStatusCandidate.score * 100 | number:'1.0-0') + '%'}} confidence
                </p>
                <div class="possible-match__btn-group">
                    <button class="btn btn-continue" (click)="continueWithMatchedPlate()">Continue with Matched plate</button>
                    <button class="btn btn-continue" routerLink="/violation/submit">Create UNPAID with Entered Plate</button>
                    <button class="btn btn-cancel" (click)="resetForm()">Cancel</button>
                </div>
            </div>
    </ng-container>
</div>
 

