<div *ngIf="!isModal" class="form-group row">
  <div class="col-md-4 col-form-label">
    <label for="uploadTicketAndText">Ticket end text</label>
  </div>
  <div class="col-md-8">
          <span class="btn btn-dark file-input-custom" *ngIf="!editorDisabled">
            <input type="file" accept=".txt"
                   (change)="townFileChanged($event)"
                   id="uploadTicketAndText"/>
            Upload ticket end text
          </span>
    <div [formGroup]="form" class="text-editor">
      <div *ngIf="editorDisabled" [innerHTML]="form.controls.htmlContent.value | sanitizeHtml"></div>
      <angular-editor *ngIf="!editorDisabled" formControlName="htmlContent" [config]="editorConfig"></angular-editor>
    </div>
  </div>
</div>

<div *ngIf="isModal" class="form-group row">
  <div class="col-12">
    <div>
      <label class="is-required"
             for="ticketEndText">Ticket end text</label>
    </div>
    <span class="btn btn-dark file-input-custom">
            <input type="file"
                   accept=".txt"
                   (change)="townFileChanged($event)"
                   id="ticketEndText"/>
            Upload ticket end text
          </span>
  </div>
  <div [formGroup]="form" class="col-12 text-editor">
    <angular-editor formControlName="htmlContent" [config]="editorConfig"></angular-editor>
  </div>
</div>
