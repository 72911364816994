import {Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {AuthenticationService} from '../../services/auth/authentication.service';
import {environment} from "../../../environments/environment";
import {TownService} from "../../services/town.service";
import {ITown} from "../../models/town";
import {NavigationService} from "../../services/navigation.service";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  contentUserDropdown = false;
  town: ITown;
  townLogoUrl = 'assets/img/countyseal.svg';

  @ViewChild('userSelectButton', {static: false}) userSelectButton: ElementRef;
  @ViewChild('userContent', {static: false}) userContent: ElementRef;

  constructor(private authenticationService: AuthenticationService,
              private renderer: Renderer2,
              private townService: TownService,
              private navigationService: NavigationService) {
    this.renderer.listen('window', 'click', (e: Event) => {
      const customDropdownElementPropertyNames = [{
        selectButtonName: 'userSelectButton',
        selectContentName: 'userContent',
        selectStateName: 'contentUserDropdown'
      }];

      this.handleDropdownOnClick(e, customDropdownElementPropertyNames);
    });
  }

  handleDropdownOnClick(e: Event, customDropdownElementPropertyNames: any[]): void {
    for (const customDropdownElementPropertyName of customDropdownElementPropertyNames) {
      const {selectButtonName, selectContentName, selectStateName} = customDropdownElementPropertyName;
      if (e.target !== this[selectButtonName]?.nativeElement && e.target !== this[selectContentName]?.nativeElement && (e.target as HTMLElement).parentElement !== this[selectButtonName]?.nativeElement) {
        this[selectStateName] = false;
      }
    }
  }

  ngOnInit(): void {
    if (this.authenticationService.userValue?.townId) {
      this.townService.getTownById(this.authenticationService.userValue?.townId).subscribe(value => {
        this.town = value;
        this.navigationService.setTown(this.town);
        this.townLogoUrl = environment.apiUrl+value.logoUrl;
      })
    }
  }

  get initials() {
    const firstAndLastName = this.authenticationService.userValue?.name?.split(' ');
    if (firstAndLastName && firstAndLastName.length > 1) {
      return firstAndLastName[0]?.charAt(0) + firstAndLastName[1]?.charAt(0);
    } else {
      return this.userName?.charAt(0);
    }
  }

  get userName() {
    return this.authenticationService.userValue?.name || this.authenticationService.userValue?.username;
  }

  logout() {
    this.authenticationService.logout();
  }

  showDropdown(){
    this.contentUserDropdown = !this.contentUserDropdown;
  }
}
