<div class="container user-account">
  <div class="row user-account__row">
    <div class="col-12 col-md-2 back-to-all-users">
      <button *ngIf="user && currentUser.role !== 'ADMIN' && user.id !== currentUser.id" type="button" class="btn back-to-all-users__btn" routerLink="../">
        <i class="bi bi-arrow-left-short back-to-all-users__icon"></i>
        <span class="back-to-all-users__label">
          <span class="back-to-all-users__label-desktop">All</span> users
        </span>
      </button>
      <button *ngIf="user && currentUser.role === 'ADMIN' && user.id === currentUser.id"  type="button" class="btn back-to-all-users__btn" routerLink="/dashboard">
        <i class="bi bi-arrow-left-short back-to-all-users__icon"></i>
        <span class="back-to-all-users__label">
          <span class="back-to-all-users__label-desktop">All</span> towns
        </span>
      </button>
    </div>
    <div class="col-12 col-md-10 col-lg-7 user-account__wrapper">
      <div class="user-account__header">
        <h2 *ngIf="user">{{ user.name }}</h2>
        <span *ngIf="isAllowedToToggleUserStatus()" class="custom-control custom-switch">
              <input [(ngModel)]="user.enabled" [checked]="user.enabled" type="checkbox" class="custom-control-input" id="user">
              <label class="custom-control-label" for="user"></label>
        </span>
        <span *ngIf="isAllowedToToggleUserStatus()" class="custom-control-span">{{user.enabled ? 'Enable' : 'Disable'}}</span>
      </div>
      <form *ngIf="user" [formGroup]="userForm" class="user-account__form" id="user-form">
        <ngb-alert *ngIf="error" (close)="closeAlert()" [type]="'danger'">{{ error }}</ngb-alert>
        <div class="form-group row">
          <label for="fullName" class="col-md-4 col-form-label">Full name</label>
          <div class="col-md-8">
            <input type="text" formControlName="fullName" class="form-control" id="fullName">
          </div>
        </div>
        <div class="form-group row">
          <label for="username" class="col-md-4 col-form-label">User name</label>
          <div class="col-md-8">
            <input type="text" formControlName="username" class="form-control" id="username">
          </div>
        </div>
        <div *ngIf="user.role !== 'ADMIN' && user.role !== currentUser.role" class="form-group row">
          <label class="is-required col-md-4 col-form-label">Role</label>
          <div class="select-container col-md-8">
            <div #roleSelectButton class="main-select form-control form-control" (click)="toggleRoleDropdown()">
              <span class="state-name">{{roleName}}</span>
            </div>
            <div #roleContent class="select-content form-control form-control"
                 *ngIf="contentRoleDropdown">
              <ul class="option-list">
                <li class="option-item"
                    *ngFor="let role of availableRoles"
                    (click)="selectRole(role)">
                  <span class="state-name">{{role.name}}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div *ngIf="user.role !== 'ADMIN' && user.role !== currentUser.role && role !== 'TM'" class="form-group row">
          <label for="badgeID" class="col-md-4 is-required col-form-label">Badge number</label>
          <div class="col-md-8">
            <input type="text" formControlName="badgeNumber" class="form-control" id="badgeID">
          </div>
        </div>
        <div *ngIf="user.role !== 'ADMIN' && user.role !== currentUser.role" class="form-group row">
          <label class="is-required col-md-4 col-form-label">Technology</label>
          <div class="select-container col-md-8">
            <div #technologySelectButton class="main-select form-control form-control" (click)="toggleTechnologyDropdown()">
              <span class="state-name">{{technologyName}}</span>
            </div>
            <div #technologyContent class="select-content form-control form-control"
                 *ngIf="contentTechnologyDropdown">
              <ul class="option-list">
                <li class="option-item"
                    *ngFor="let technology of technologies"
                    (click)="selectTechnology(technology)">
                  <span class="state-name">{{technology}}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="form-group row no-gutters" [ngClass]="{'d-none': changePasswordSection}" *ngIf="isAuthorizedToEdit()">
          <button class="btn change-password__btn" (click)="showChangePassword()">Change password</button>
        </div>
        <ng-container *ngIf="changePasswordSection">
          <h3 class="change-password__header">New password</h3>

          <div *ngIf="user.id === currentUser.id" class="form-group row">
            <label for="newPassword" class="col-md-4 col-form-label is-required">Old Password</label>
            <div class="col-md-8">
              <input formControlName="oldPassword" type="password" class="form-control" id="oldPassword">
            </div>
          </div>

          <div class="form-group row">
            <label for="newPassword" class="col-md-4 col-form-label is-required">New Password</label>
            <div class="col-md-8">
              <input formControlName="password" type="password" class="form-control" id="newPassword">
            </div>
          </div>
          <div class="form-group row">
            <label for="confirmPassword" class="col-md-4 col-form-label is-required">Confirm Password</label>
            <div class="col-md-8">
              <input formControlName="confirmPassword" type="password" class="form-control" id="confirmPassword">
            </div>
          </div>
        </ng-container>
      </form>
      <div class="user-account__btn-group">
        <div class="user-account__btn-wrapper">
          <button *ngIf="user" [disabled]="userForm.invalid || (roleIsEmpty() && user.role !== 'ADMIN') || (technologyIsEmpty() && user.role !== 'ADMIN')"
                  [class.active]="!userForm.invalid && ((!roleIsEmpty() && !technologyIsEmpty()) || user.role === 'ADMIN')"
            class="btn user-account__btn-save" (click)="onSubmit()">Save changes</button>
          <button class="btn user-account__btn-cancel" (click)="goBack()">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</div>
<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
