<nav class="container-fluid navbar-container">
  <div class="container-md">
    <div class="row justify-content-between">
      <div class="logo__wrapper col-8">
        <div class="logo__img">
          <a routerLink="/">
            <img [src]="townLogoUrl" alt="Plymouth County">
          </a>
        </div>
        <h1 class="logo__header">{{town?.name || 'Plymouth County'}}</h1>
      </div>
      <div class="account">
        <div class="user" (click)="showDropdown()">
          <div class="user__name d-none d-sm-block">
            {{userName}}
          </div>
          <div class="user__img" *ngIf="userName" #userSelectButton>
            <div class="user__initials">{{initials | uppercase}}</div>
          </div>
        </div>
        <div *ngIf="contentUserDropdown" class="dropdown-menu-right" aria-labelledby="profileDropdown" #userContent>
          <button (click)="logout()" class="btn btn-logout">Logout</button>
        </div>
      </div>
    </div>
  </div>
</nav>


