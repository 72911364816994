<link rel="stylesheet" *ngIf="cssUrl" [href]='sanitizer.bypassSecurityTrustResourceUrl(cssUrl)'>
<ng-container *ngIf="violationRecord; else noViolationRecord">
  <div class="container ticket-preview">
    <div class="row">
      <div class="col-md-12">
        <h1 class="text-center ticket-header">VIOLATION</h1>
      </div>
    </div>
    <div>
      <div align="center">
        <a routerLink="/">
          <img style="width: 200px" [src]="townLogoUrl" alt="Town logo">
        </a>
      </div>
    </div>
    <div class="normal-print-size">
      <div [ngClass]="['row', marginBottomClass]">
        <div class="col-md text-center">
          <span *ngIf="town">{{town.townFormalName}}</span>
          <span>Violation #</span>
          <span>{{violationRecord.serialNumber}}</span>
        </div>
      </div>
      <div [ngClass]="['row', marginBottomClass]">
        <div class="col-md text-center">
          <span>Date: {{violationRecord.date}}</span>
          <span *ngIf="!violationRecord.endTime">Time: {{violationRecord.startTime}}</span>
          <span *ngIf="violationRecord.endTime">Time: {{ violationRecord.startTime }} to {{violationRecord.endTime}}</span>
        </div>
      </div>
      <div [ngClass]="['row', marginBottomClass]">
        <div class="col-md text-center">
          <span>License Plate:</span>
          <span>{{violationRecord.state}} {{violationRecord.plateType}} {{violationRecord.plateColor}}</span>
          <span class="plate-number">{{violationRecord.licensePlateNumber | uppercase}}</span>
          <span>Color/Make: {{violationRecord.color | uppercase}} {{violationRecord.make | uppercase}}</span>
        </div>
      </div>
      <div [ngClass]="['row', marginBottomClass]">
        <div class="col-md text-center">
          <span>Location:</span>
          <span>{{violationRecord.location}}</span>
        </div>
      </div>
      <ng-container *ngIf="violationRecord.meterNumber">
        <div [ngClass]="['row', marginBottomClass]">
          <div class="col-md text-center">
            <span>Meter Number:</span>
            <span>{{violationRecord.meterNumber}}</span>
          </div>
        </div>
      </ng-container>
      <div [ngClass]="['row', marginBottomClass]">
        <div class="col-md text-center">
          <span>Violations:</span>
          <ng-container *ngFor="let violationType of violationRecord.violationTypes">
          <span>{{violationType.violationTypeCode}} {{violationType.violationTypeName}}
            <ng-container *ngIf="violationType.descriptionForOtherOption">
              ({{violationType.descriptionForOtherOption}})
            </ng-container>
          </span>
            <span>Fine: {{violationType.violationTypeFine}}</span>
          </ng-container>
        </div>
      </div>
      <ng-container *ngIf="violationRecord.notes">
        <div [ngClass]="['row', marginBottomClass]">
          <div class="col-md text-center">
            <span>Notes:</span>
            <span>{{violationRecord.notes}}</span>
          </div>
        </div>
      </ng-container>

    <div [ngClass]="['row', marginBottomClass]">
      <div class="col-md text-center">
        <span><strong>AMOUNT DUE:</strong></span>
        <span><strong>{{violationRecord.totalFine}}</strong></span>
      </div>
    </div>
    <div [ngClass]="['row', marginBottomClass]">
      <div class="col-md text-center">
        <span>I have attempted to affix this notice to vehicle.</span>
        <span>Officer <strong>{{violationRecord.name}}</strong> Badge No. <strong>{{violationRecord.badgeId}}</strong></span>
      </div>
    </div>
    </div>
    <div class="row">
      <div class="col-md important-notice">
        <strong>TO THE OFFENDER:</strong>
        <strong>IMPORTANT NOTICE! PLEASE READ CAREFULLY</strong>
      </div>
    </div>
    <div *ngIf="town" class="row mb-4">
      <div [innerHTML]="town.endText | sanitizeHtml" class="col-md notice"></div>
    </div>
    <div class="col-md notice"><hr class="ticket-hr"></div>
    <div class="row">
      <div class="col-md text-center">
        <button routerLink="/" class="btn btn-secondary" [disabled]="!printEventFired">Home</button>
      </div>

      <div class="col-md text-center">
        <button class="btn btn-ps-primary" (click)="printPage()">Print ticket</button>
      </div>
    </div>
  </div>

</ng-container>
<ng-template #noViolationRecord>
  <div class="container mt-5">
    <div class="alert alert-danger" role="alert" id="noViolationRecord">
      <h1 class="alert-heading text-center">You haven't submitted violation</h1>
      <p class="lead text-center">Submit the violation first and then you will be automatically redirected to this page
        after successful submission.
      </p>
      <hr>
      <ul>
        <li><a routerLink="/">Home page</a></li>
        <li><a routerLink="/violation/submit">Violation submission page</a></li>
      </ul>
    </div>
  </div>
</ng-template>
