import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {ParkingStatus} from '../models/parking-status';
import {PlateNumberService} from './platenumber.service';
import {ViolationRecord} from '../models/violation-record';
import {ViolationFormFieldsData} from "../models/violation-form-fields-data";
import {IViolationType} from "../models/violation-type";

@Injectable({
  providedIn: 'root'
})
export class ViolationService {

  private violationRecordSubject: BehaviorSubject<ViolationRecord>;

  constructor(private http: HttpClient, private plateNumberService: PlateNumberService) {
    this.violationRecordSubject = new BehaviorSubject<ViolationRecord>(null);
  }

  private httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
  };

  getViolationFormFieldsData(id: number): Observable<ViolationFormFieldsData> {
    return this.http.get<ViolationFormFieldsData>(`${environment.apiUrl}/violation/violationFormFieldsData/` + id);
  }

  getViolationsByTownId(id: number, page: number, search?: string): Observable<any> {
    let url = `${environment.apiUrl}/violation/all/town/${id}?page=${page}`;
    if (search) {
      url = url + `&search=${search}`
    }
    return this.http.get(url);
  }

  getViolationTypeById(id: number): Observable<IViolationType> {
    return this.http.get<IViolationType>(`${environment.apiUrl}/violation/type/` + id);
  }

  getParkingStatusByPlateNumber(plateNumber: string, townId: number, zoneId: number): Observable<ParkingStatus> {
    this.plateNumberService.setPlateNumber(plateNumber);
    return this.http.get<ParkingStatus>(`${environment.apiUrl}/violation/check/${plateNumber}?townId=${townId}&zoneId=${zoneId}`);
  }

  getAvailableTownZones(townId: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}/violation/zones/${townId}`);
  }


  submitViolation(requestBody: object): Observable<any> {
    return this.http.post(`${environment.apiUrl}/violation/submit`, requestBody);
  }

  submitViolationType(requestBody: object): Observable<any> {
    return this.http.post<IViolationType>(`${environment.apiUrl}/violation/type/submit`, requestBody);
  }

  setNextViolationRecord(violationRecord: ViolationRecord) {
    this.violationRecordSubject.next(violationRecord);
  }

  getViolationRecord(): ViolationRecord {
    return this.violationRecordSubject.value;
  }

  getViolationRecordById(violationRecordId: number): Observable<ViolationRecord> {
    return this.http.get<ViolationRecord>(`${environment.apiUrl}/violation/${violationRecordId}`);
  }

  async getAccessoryDataById(accessoryDataId: string): Promise<Blob> {
    return await this.http.get(`${environment.apiUrl}/violation/accessoryData/${accessoryDataId}`, { responseType: 'blob' }).toPromise();
  }

  getViolationTypesByTown(page: number, townId: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}/violation/type/town/${townId}?page=` + page);
  }

  importViolationsAndFinesFromCSV(requestBody: object, townId: number): Observable<any> {
    return this.http.post(`${environment.apiUrl}/violation/import/${townId}`, requestBody);
  }

  downloadViolationAccessoryData(violationRecordId: number): Observable<Blob>{
    const downloadAccessoryDataHttpOptions = {
      responseType: 'blob' as 'json',
    };
    return this.http.get<Blob>(`${environment.apiUrl}/violation/download/${violationRecordId}`, downloadAccessoryDataHttpOptions);
  }

  exportViolationsAndFinesAsCSV(townId: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}/violation/export/${townId}`, {responseType: 'blob'});
  }

  changeViolationStatus(id:number, formData: FormData): Observable<any> {
    return this.http.put(`${environment.apiUrl}/violation/status/${id}`, formData);
  }
}
