import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ViolationService} from "../../../services/violation.service";
import {ActivatedRoute} from "@angular/router";
import {IViolationType, ViolationType} from "../../../models/violation-type";
import {Location} from "@angular/common";
import {ToastService} from "../../../services/toast-service";
import {forkJoin} from "rxjs";

@Component({
  selector: 'app-violation-details',
  templateUrl: './violation-details.component.html',
  styleUrls: ['../../../app.component.scss', './violation-details.component.scss']
})
export class ViolationDetailsComponent implements OnInit {

  id: any;
  violation: IViolationType;

  violationForm: FormGroup;

  error = '';

  constructor(private route: ActivatedRoute, private violationService: ViolationService,
              private routerLocation: Location, private toastService: ToastService) {
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    let violationTypeById = this.violationService.getViolationTypeById(this.id);
    forkJoin([violationTypeById]).subscribe(results => {
      this.violation = results[0];
      this.initForm(this.violation);
    });
  }

  initForm(violation: IViolationType) {
    let fee = violation.violationTypeFine.replace('$', '');
    this.violationForm = new FormGroup({
      code: new FormControl(violation.violationTypeCode, Validators.required),
      fee: new FormControl(fee.split('.')[0],
        [Validators.required, Validators.pattern("^[0-9]*$")]),
      violation: new FormControl(violation.violationTypeName, Validators.required)
    });
  }

  get form() {
    return this.violationForm.controls;
  }

  closeAlert() {
    this.error = null;
  }

  goBack() {
    this.routerLocation.back();
  }

  onSubmit() {
    const formData = new FormData();

    const violation = new ViolationType();
    violation.setViolationFields({
      id: this.id, violationTypeCode: this.form.code.value, townId: this.violation.townId,
      violationTypeName: this.form.violation.value, violationTypeFine: this.form.fee.value, enabled: this.violation.enabled
    });

    formData.append("violation", JSON.stringify(violation));

    this.violationService.submitViolationType(formData).subscribe(response => {
        this.violation = response;
        this.updateForm(response);
        this.toastService.show('Violation Type has been successfully updated',
          {classname: 'bg-success text-light', autohide: true, delay: 2000});
      },
      error => {
        this.error = error;
      });
  }

  private updateForm(violation: IViolationType) {
    let fee = violation.violationTypeFine.replace('$', '');
    this.violationForm.controls.code.setValue(violation.violationTypeCode);
    this.violationForm.controls.fee.setValue(fee.split('.')[0]);
    this.violationForm.controls.violation.setValue(violation.violationTypeName);
  }

}
