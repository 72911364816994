import {Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {UserService} from "../../../services/user.service";
import {User} from "../../../models/user";
import {ToastService} from "../../../services/toast-service";
import {Role} from "../../../models/role";
import {TownService} from "../../../services/town.service";
import {NavigationService} from "../../../services/navigation.service";
import {AuthenticationService} from "../../../services/auth/authentication.service";

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['../../../app.component.scss', './users-list.component.scss']
})
export class UsersListComponent implements OnInit {

  private routeSubscription: Subscription;

  contentTechnologyDropdown = false;
  contentRoleDropdown = false;
  technologies = ['Tablet', 'Laptop', 'N/A'];
  technologyName: string;
  roles = [{name: 'Parking Enforcement', role: Role.PEA}, {name: 'Town Admin', role: Role.TM}, {
    name: 'Law Enforcement',
    role: Role.LEO
  }];
  roleName: string;
  role: Role;

  userForm: FormGroup;

  users = [];

  townId = 0;

  currentPage = 0;
  totalElements;
  size;

  @ViewChild("role", {static: false}) roleSpanRef: ElementRef;
  @ViewChild("technology", {static: false}) technologySpanRef: ElementRef;

  error = '';

  private modal: NgbModalRef;

  @ViewChild('technologySelectButton', {static: false}) technologySelectButton: ElementRef;
  @ViewChild('technologyContent', {static: false}) technologyContent: ElementRef;
  @ViewChild('roleSelectButton', {static: false}) roleSelectButton: ElementRef;
  @ViewChild('roleContent', {static: false}) roleContent: ElementRef;

  constructor(private modalService: NgbModal, private renderer: Renderer2,
              private route: ActivatedRoute, private userService: UserService,
              private toastService: ToastService, private townService: TownService,
              private navigationService: NavigationService,
              private authenticationService: AuthenticationService) {
    this.routeSubscription = route.parent.params.subscribe(params => this.townId = params.id);

    this.renderer.listen('window', 'click', (e: Event) => {
      const customDropdownElementPropertyNames = [{
        selectButtonName: 'technologySelectButton',
        selectContentName: 'technologyContent',
        selectStateName: 'contentStateDropdown'
      }, {
        selectButtonName: 'roleSelectButton',
        selectContentName: 'roleContent',
        selectRoleName: 'contentRoleDropdown'
      }];

      this.handleDropdownOnClick(e, customDropdownElementPropertyNames);
    });
  }

  handleDropdownOnClick(e: Event, customDropdownElementPropertyNames: any[]): void {
    for (const customDropdownElementPropertyName of customDropdownElementPropertyNames) {
      const {selectButtonName, selectContentName, selectStateName} = customDropdownElementPropertyName;
      if (e.target !== this[selectButtonName]?.nativeElement && e.target !== this[selectContentName]?.nativeElement && (e.target as HTMLElement).parentElement !== this[selectButtonName]?.nativeElement) {
        this[selectStateName] = false;
      }
    }
  }

  openBackDropCustomClass(content) {
    this.modal = this.modalService.open(content, {backdropClass: 'light-blue-backdrop'});
  }

  openSaveChanges(contentSaveChanges) {
    this.modalService.open(contentSaveChanges, {backdropClass: 'light-blue-backdrop'});
  }

  ngOnInit(): void {
    this.loadUsers(0);
    this.initForm();
  }

  loadUsers(page: number) {
    this.userService.getAllUsersInTownPageable(this.townId, page).subscribe(response => {
      this.users = response.content;
      this.totalElements = response.totalElements;
      this.size = response.size;
    });
  }

  initForm() {
    this.userForm = new FormGroup({
      fullName: new FormControl('', Validators.required),
      username: new FormControl('', Validators.required),
      badgeNumber: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
      confirmPassword: new FormControl('')
    });
  }

  toggleTechnologyDropdown() {
    this.contentTechnologyDropdown = !this.contentTechnologyDropdown;
  }

  selectTechnology(technology: string) {
    this.technologyName = technology;
    this.contentTechnologyDropdown = false;
  }

  toggleRoleDropdown() {
    this.contentRoleDropdown = !this.contentRoleDropdown;
  }

  selectRole(role: any) {
    this.roleName = role.name;
    this.role = role.role;
    this.contentRoleDropdown = false;
    if (this.role === Role.TM) {
      this.userForm.controls.badgeNumber.clearValidators()
    } else {
      this.userForm.controls.badgeNumber.setValidators(Validators.required);
    }
    this.userForm.controls.badgeNumber.updateValueAndValidity();
  }

  closeModal() {
    this.modal.close();
    this.resetForm();
  }

  closeAlert() {
    this.error = null;
  }

  get form() {
    return this.userForm.controls;
  }

  resetForm() {
    this.userForm.reset();
    this.error = '';
    this.technologyName = '';
    this.roleName = '';
  }

  public onPageChange(pageNum: number): void {
    this.currentPage = pageNum - 1;
    this.loadUsers(this.currentPage);
  }

  onSubmit() {
    const formData = new FormData();

    let username = this.form.username.value;
    let name = this.form.fullName.value;
    let badgeId = this.form.badgeNumber.value;
    let password = this.form.password.value;
    let confirmPassword = this.form.confirmPassword.value;

    const user = new User();
    user.setUserFields({
      username: username, name: name, badgeId: badgeId, password: password, townId: this.townId,
      confirmPassword: confirmPassword, technology: this.technologyName, role: this.role
    });

    formData.append("user", JSON.stringify(user));

    this.userService.createUser(formData).subscribe(() => {
        this.loadUsers(this.currentPage);
        this.closeModal();
        this.toastService.show('User has been successfully created',
          {classname: 'bg-success text-light', autohide: true, delay: 2000});
      },
      error => {
        this.error = error;
      });
  }

  printPEAChart() {
    this.userService.printChart(this.townId).subscribe(response => {
      const fileName = this.navigationService.townValue.name + '-users.csv';
      const reader = new FileReader();
      const out = new Blob([response], {type: response.type});
      reader.onload = function(e){
        let downloadLink = document.createElement('a');
        downloadLink.href = reader.result as string;
        downloadLink.setAttribute('download', fileName);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        downloadLink.remove();
      };
      reader.readAsDataURL(out);
    });
  }

  changeUserStatus(userId: number, status: boolean) {
    const formData = new FormData();
    formData.append("status", String(status));
    this.userService.changeUserStatus(userId, formData).subscribe(() => {
        this.loadUsers(this.currentPage);
        this.toastService.show('User status has been successfully updated',
          {classname: 'bg-success text-light', autohide: true, delay: 2000});
      },
      error => {
        this.loadUsers(this.currentPage);
        this.toastService.show(`User status has not been updated. Reason: ${error}`,
          {classname: 'bg-danger text-light', autohide: true, delay: 2000});
      });
  }

  roleIsEmpty() {
    return (!this.roleName || this.roleName.length === 0 );
  }

  technologyIsEmpty() {
    return (!this.technologyName || this.technologyName.length === 0 );
  }

  get availableRoles() {
    return this.roles.filter(({role}) => this.authenticationService.userValue.role !== role);
  }
}
