<div id="layoutAuth" class="wrapper">
  <app-navbar></app-navbar>
  <div class="container-md body">

    <div id="layoutAuth_content">
      <div class="header">
        <h3 class="header__title">{{headerTitle}}</h3>
      </div>
      <div class="violation__content">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>




