import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {IpService} from "../services/ip.service";

@Injectable()
export class IpInterceptor implements HttpInterceptor {

  private ip: string = null;

  constructor(private ipService: IpService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const ipCheckEnabled: boolean = environment.ipCheckEnabled;
    const isApiUrl: boolean = request.url.startsWith(environment.apiUrl);
    if (ipCheckEnabled && isApiUrl) {
      this.ip = localStorage.getItem('ip');
      if (!this.ip) {
        this.ipService.getIpAddress().subscribe((result: any) => {
          this.ip = result.ip;
          localStorage.setItem('ip', this.ip);
        })
      }

      request = request.clone({
        setHeaders: {
          Ip: this.ip !== null ? this.ip : 'null'
        }
      });
    }
    return next.handle(request);
  }

}
