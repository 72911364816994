import {Role} from './role';

export class IUser {
  id?: number;
  username?: string;
  name?: string;
  oldPassword?: string;
  confirmPassword?: string;
  password?: string;
  role?: Role;
  expiresAt?: number;
  token?: string;
  badgeId?: string;
  technology?: string;
  enabled?: boolean;
  townId?: number;
}

export class User implements IUser {

  constructor(
    public id?: number,
    public username?: string,
    public name?: string,
    public oldPassword?: string,
    public confirmPassword?: string,
    public password?: string,
    public role?: Role,
    public expiresAt?: number,
    public token?: string,
    public badgeId?: string,
    public technology?: string,
    public enabled?: boolean,
    public townId?: number) {

    this.id = id ? id : 0;
    this.name = name ? name : '';
    this.username = username ? username : '';
    this.oldPassword = oldPassword ? oldPassword : '';
    this.confirmPassword = confirmPassword ? confirmPassword : '';
    this.password = password ? password : '';
    this.role = role ? role : undefined;
    this.expiresAt = expiresAt ? expiresAt : 0;
    this.token = token ? token : '';
    this.badgeId = badgeId ? badgeId : '';
    this.technology = technology ? technology : '';
    this.enabled = enabled ? enabled : false;
    this.townId = townId ? townId : 0;
  }

  public setUserFields(state: IUser): void {
    this.id = state.id ? state.id : this.id;
    this.username = state.username ? state.username : this.username;
    this.name = state.name ? state.name : this.name;
    this.oldPassword = state.oldPassword ? state.oldPassword : this.oldPassword;
    this.confirmPassword = state.confirmPassword ? state.confirmPassword : this.confirmPassword;
    this.password = state.password ? state.password : this.password;
    this.role = state.role ? state.role : this.role;
    this.expiresAt = state.expiresAt ? state.expiresAt : this.expiresAt;
    this.token = state.token ? state.token : this.token;
    this.badgeId = state.badgeId ? state.badgeId : this.badgeId;
    this.technology = state.technology ? state.technology : this.technology;
    this.enabled = state.enabled ? state.enabled : this.enabled;
    this.townId = state.townId ? state.townId : this.townId;
  }

}
