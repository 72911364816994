import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ParkingStatus} from "../../../models/parking-status";

@Component({
  selector: 'app-parking-status',
  templateUrl: './parking-status.component.html',
  styleUrls: ['../../../app.component.scss',
    './parking-status.component.css']
})
export class ParkingStatusComponent implements OnInit {

  unpaidParkingMessage = 'Unpaid parking';
  overtimeParkingMessage = 'Overtime violation';
  paidParkingMessage = 'Paid - no violation';
  @Input() parkingStatus: ParkingStatus;
  @Output() nextVehicleEvent = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }

  get parkingStatusMessage() {
    if (!this.parkingStatus.parkingPaid) {
      return this.unpaidParkingMessage;
    } else if (this.parkingStatus.parkingPaid && this.parkingStatus.overtimeParking) {
      return this.overtimeParkingMessage;
    } else if (this.parkingStatus.parkingPaid && !this.parkingStatus.overtimeParking) {
      return this.paidParkingMessage;
    }
  }

  hasViolation() {
    return !this.parkingStatus.parkingPaid || this.parkingStatus.overtimeParking;
  }

  nextVehicle() {
    this.nextVehicleEvent.emit(null);
  }

}
