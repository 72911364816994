import {IUser} from "./user";

export class ITown {
  id?: number;
  name?: string;
  logoUrl?: string;
  endText?: string;
  enabled?: boolean;
  apiUsed?: boolean;
  townCode?: string;
  townManagers?: IUser[];
  series?: number;
  ticketStart?: number;
  townFormalName?: string
}

export class Town implements ITown {

  constructor(
    public id?: number,
    public name?: string,
    public logoUrl?: string,
    public endText?: string,
    public enabled?: boolean,
    public apiUsed?: boolean,
    public townCode?: string,
    public townManagers?: IUser[],
    public series?: number,
    public ticketStart?: number,
    public townFormalName?: string
  ) {
    this.id = id ? id : 0;
    this.name = name ? name : '';
    this.logoUrl = logoUrl ? logoUrl : '';
    this.endText = endText ? endText : '';
    this.enabled = enabled ? enabled : false;
    this.apiUsed = apiUsed ? apiUsed : false;
    this.townCode = townCode ? townCode : '';
    this.townManagers = townManagers ? townManagers : [];
    this.series = series || 0;
    this.ticketStart = ticketStart || 0;
    this.townFormalName = townFormalName || '';
  }

  public setTowsFields(state: ITown): void {
    this.id = state.id ? state.id : this.id;
    this.name = state.name ? state.name : this.name;
    this.logoUrl = state.logoUrl ? state.logoUrl : this.logoUrl;
    this.endText = state.endText ? state.endText : this.endText;
    this.enabled = state.enabled ? state.enabled : this.enabled;
    this.apiUsed = state.apiUsed ? state.apiUsed : this.apiUsed;
    this.townCode = state.townCode ? state.townCode : this.townCode;
    this.townManagers = state.townManagers ? state.townManagers : this.townManagers;
    this.series = state.series || this.series;
    this.ticketStart = state.ticketStart || this.ticketStart;
    this.townFormalName = state.townFormalName || this.townFormalName;
  }
}
