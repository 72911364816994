import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {Injectable} from '@angular/core';
import {catchError} from 'rxjs/operators';
import {AuthenticationService} from '../services/auth/authentication.service';
import * as Sentry from "@sentry/angular";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private authenticationService: AuthenticationService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(catchError(err => {
      if (err.status === 401 || err.status === 403) {
        this.authenticationService.refresh(err.status);
      } else if (err.status === 500 || err.status === 502) {
        Sentry.captureException(err);
      }
      const error = typeof err.error === "string" ? err.error : err.error?.message;
      return throwError(error || 'Error');
    }));
  }

}
