import {Injectable} from "@angular/core";
import {BehaviorSubject, Subject} from "rxjs";
import {ITown} from "../models/town";

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private emitChangeSource = new Subject<number>();
  changeEmitted$ = this.emitChangeSource.asObservable();
  private townBehaviorSubject = new BehaviorSubject<ITown>(null);
  townEmitted$ = this.townBehaviorSubject.asObservable();
  emitChange(change: number) {
    if (!change) {
      this.townBehaviorSubject.next(null);
    }
    this.emitChangeSource.next(change);
  }

  setTown(town: ITown) {
    this.townBehaviorSubject.next(town);
  }

  get townValue() {
    return this.townBehaviorSubject.value;
  }
}
