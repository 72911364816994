import {Component, OnInit} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {TownService} from '../../../services/town.service';
import {DomSanitizer} from '@angular/platform-browser';
import {ITown, Town} from '../../../models/town';
import {environment} from '../../../../environments/environment';
import {ToastService} from '../../../services/toast-service';
import {ViolationService} from '../../../services/violation.service';
import {NavigationService} from "../../../services/navigation.service";

@Component({
  selector: 'app-towns-list',
  templateUrl: './towns-list.component.html',
  styleUrls: ['./towns-list.component.css',
  '../../../app.component.scss']
})

export class TownsListComponent implements OnInit {

  id: any;
  logo: any;
  imgURL: any;
  environment: any;
  isFileSet: boolean = false;
  townForm = new FormGroup({});
  towns: ITown[];

  currentPage = 0;
  totalElements;
  size;

  error = '';

  private routeSubscription: Subscription;
  private modal: NgbModalRef;

  constructor(private modalService: NgbModal, private route: ActivatedRoute,
              private townService: TownService, protected sanitizer: DomSanitizer,
              private toastService: ToastService,
              private tempViolationService: ViolationService,
              private navigationService: NavigationService) {
    this.routeSubscription = route.params.subscribe(params => this.id = params['id']);
  }

  openBackDropCustomClass(content) {
    this.modal = this.modalService.open(content, {backdropClass: 'light-blue-backdrop'});
  }

  ngOnInit(): void {
    this.initForm();
    this.loadTowns(0);
    this.environment = environment;
    this.navigationService.emitChange(undefined);
  }

  get form() {
    return this.townForm.controls;
  }

  closeAlert() {
    this.error = null;
  }

  initForm() {
    this.townForm = new FormGroup({
      townName: new FormControl('', Validators.required),
      townCode: new FormControl('', Validators.required),
      series: new FormControl('', [Validators.min(1), Validators.max(9), Validators.pattern("^[0-9]*$"), Validators.required]),
      ticketStart: new FormControl('',  [Validators.min(0), Validators.maxLength(8), Validators.pattern("^[0-9]*$"), Validators.required]),
      htmlContent: new FormControl('', Validators.required),
      townLogo: new FormControl(''),
      kiosk: new FormControl('yes', Validators.required),
      townFormalName: new FormControl()
    });
  }

  loadTowns(page: number) {
    this.townService.getAllTowns(page).subscribe(value => {
      this.towns = value.content;
      this.totalElements = value.totalElements;
      this.size = value.size;
    });
  }

  resetForm() {
    this.townForm.reset();
    this.townForm.controls.kiosk.setValue('yes');
    this.imgURL = null;
    this.isFileSet = false;
    this.error = '';
  }

  closeModal() {
    this.modal.close();
    this.resetForm();
  }

  onFileChange(event) {
    this.logo = Array.from(event.target.files);
    if (this.logo.length > 0) {
      this.isFileSet = true;
      const fileReader = new FileReader();
      fileReader.onload = async e => {
        this.imgURL = this.sanitizer.bypassSecurityTrustUrl(fileReader.result as string);
      };
      fileReader.readAsDataURL(event.target.files[0]);
    }
  }

  public onPageChange(pageNum: number): void {
    this.currentPage = pageNum - 1;
    this.loadTowns(this.currentPage);
  }

  public onEnableTownChange(event) {
    this.townService.changeTownStatus(event.target.id, event.target.checked).subscribe(() => {
      const status = event.target.checked ? 'enabled' : 'disabled';
        this.toastService.show('Town has been successfully ' + status,
          { classname: 'bg-success text-light', autohide: true, delay: 2000});
      },
      error => {
        this.toastService.show(`Could not change town's status because of the error: ${error} `,
          { classname: 'bg-danger text-light', autohide: true, delay: 5000});
      });
  }

  onSubmit() {
    const formData = new FormData();
    const kiosk = this.form.kiosk.value == 'yes';

    const town = new Town();
    town.setTowsFields({
      id: 0, name: this.form.townName.value, apiUsed: kiosk,
      endText: this.form.htmlContent.value, townCode: this.form.townCode.value,
      series: this.form.series.value, ticketStart: this.form.ticketStart.value,
      townFormalName: this.form.townFormalName.value
    });

    formData.append("town", JSON.stringify(town));
    formData.append("file", this.logo[0]);

    this.townService.saveTown(formData).subscribe(() => {
        this.loadTowns(this.currentPage);
        this.closeModal();
        this.toastService.show('Town has been successfully created',
          { classname: 'bg-success text-light', autohide: true, delay: 2000});
      },
      error => {
        this.error = error;
      });
  }
}
