import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-violation',
  templateUrl: './violation.component.html',
  styleUrls: ['./violation.component.css']
})
export class ViolationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
