import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ZoneService {

  private zoneNumberSubject: BehaviorSubject<string>;
  private zoneLocationSubject: BehaviorSubject<string>;

  constructor() {
    this.zoneNumberSubject = new BehaviorSubject<string>(null);
    this.zoneLocationSubject = new BehaviorSubject<string>(null);
  }

  setZoneNumber(zoneNumber: string) {
    this.zoneNumberSubject.next(zoneNumber);
  }

  getZoneNumber(): string {
    return this.zoneNumberSubject.value || '';
  }

  clearZoneNumber() {
    this.zoneNumberSubject.next(null);
  }

  setZoneLocation(zoneLocation: string) {
    this.zoneLocationSubject.next(zoneLocation);
  }

  getZoneLocation(): string {
    return this.zoneLocationSubject.value || '';
  }

  clearZoneLocation() {
    this.zoneLocationSubject.next(null);
  }

}
