import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ParkingStatus } from 'src/app/models/parking-status';
import {PlateRecognizerResponseService} from "../../../services/plate-recognizer-response.service";

@Component({
  selector: 'app-possible-match',
  templateUrl: './possible-match.component.html',
  styleUrls: ['../../../app.component.scss',
              './possible-match.component.css']
})
export class PossibleMatchComponent implements OnInit {

  @Input() parkingStatus: ParkingStatus;
  @Input() plateNumber: string;
  @Output() formReset = new EventEmitter();
  @Output() plateMatched = new EventEmitter();

  private _fuzzyLogicMatchBlockVisible = false;

  constructor(private plateRecognizerResponseService: PlateRecognizerResponseService) { }

  ngOnInit(): void {
  }

  showFuzzyLogicMatch(): void {
    this._fuzzyLogicMatchBlockVisible = true;
  }

  get fuzzyLogicMatchBlockVisible() {
    return this._fuzzyLogicMatchBlockVisible;
  }

  resetForm() {
    this.plateRecognizerResponseService.resetNumberOfUnsuccessfulRecognizerAttempts();
    this.plateRecognizerResponseService.resetNumberOfUnsuccessfulBarcodeAttempts();
    this.formReset.emit(null);
    this._fuzzyLogicMatchBlockVisible = false;
  }

  continueWithMatchedPlate() {
    this.plateMatched.emit(null);
  }


}
