import {Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Location} from "@angular/common";
import {forkJoin} from "rxjs";
import {UserService} from "../../../services/user.service";
import {ActivatedRoute} from "@angular/router";
import {User} from "../../../models/user";
import {Role} from "../../../models/role";
import {ToastService} from "../../../services/toast-service";
import {AuthenticationService} from "../../../services/auth/authentication.service";
import {NavigationService} from "../../../services/navigation.service";
import {concatMap} from "rxjs/operators";

@Component({
  selector: 'app-user-account',
  templateUrl: './user-account.component.html',
  styleUrls: ['./user-account.component.scss', '../../../app.component.scss']
})
export class UserAccountComponent implements OnInit {

  contentTechnologyDropdown = false;
  contentRoleDropdown = false;
  changePasswordSection = false;
  technologies = ['Tablet', 'Laptop', 'N/A'];
  technologyName: string;
  roles = [{name: 'Parking Enforcement', role: Role.PEA}, {name: 'Town Admin', role: Role.TM}, {name: 'Law Enforcement', role: Role.LEO}];
  roleName: string;
  user;
  currentUser;

  userId;
  townId;
  role: Role;

  userForm: FormGroup;

  error = '';

  @ViewChild('technologySelectButton', {static: false}) technologySelectButton: ElementRef;
  @ViewChild('technologyContent', {static: false}) technologyContent: ElementRef;
  @ViewChild('roleSelectButton', {static: false}) roleSelectButton: ElementRef;
  @ViewChild('roleContent', {static: false}) roleContent: ElementRef;

  constructor(private renderer: Renderer2, private routerLocation: Location,
              private userService: UserService, private route: ActivatedRoute,
              private toastService: ToastService, private authenticationService: AuthenticationService,
              private navigationService: NavigationService) {
    this.renderer.listen('window', 'click', (e: Event) => {
      const customDropdownElementPropertyNames = [{
        selectButtonName: 'technologySelectButton',
        selectContentName: 'technologyContent',
        selectStateName: 'contentTechnologyDropdown'
      }, {
        selectButtonName: 'roleSelectButton',
        selectContentName: 'roleContent',
        selectStateName: 'contentRoleDropdown'
      }];

      this.handleDropdownOnClick(e, customDropdownElementPropertyNames);
    });
  }

  handleDropdownOnClick(e: Event, customDropdownElementPropertyNames: any[]): void {
    for (const customDropdownElementPropertyName of customDropdownElementPropertyNames) {
      const {selectButtonName, selectContentName, selectStateName} = customDropdownElementPropertyName;
      if (e.target !== this[selectButtonName]?.nativeElement && e.target !== this[selectContentName]?.nativeElement && (e.target as HTMLElement).parentElement !== this[selectButtonName]?.nativeElement) {
        this[selectStateName] = false;
      }
    }
  }

  ngOnInit(): void {
    this.route.params.pipe(
      concatMap(params => {
        this.userId = params.id;
        let userById = this.userService.getUserById(this.userId);
        return forkJoin([userById])
      })
    ).subscribe(results => {
      this.user = results[0];
      this.initForm(this.user);
    });
    this.route.parent.params.subscribe(params => this.townId = params.id);
    this.currentUser = this.authenticationService.userValue;
    if (this.townId == 0) {
      this.navigationService.emitChange(undefined);
    }
  }

  toggleTechnologyDropdown() {
    this.contentTechnologyDropdown = !this.contentTechnologyDropdown;
  }

  selectTechnology(technology: string) {
    this.technologyName = technology;
    this.contentTechnologyDropdown = false;
  }

  toggleRoleDropdown() {
    this.contentRoleDropdown = !this.contentRoleDropdown;
  }

  selectRole(role: any) {
    this.roleName = role.name;
    this.role = role.role;
    this.contentRoleDropdown = false;
    if (this.role === Role.TM) {
      this.userForm.controls.badgeNumber.clearValidators()
    } else {
      this.userForm.controls.badgeNumber.setValidators(Validators.required);
    }
    this.userForm.controls.badgeNumber.updateValueAndValidity();
  }

  showChangePassword(){
    this.changePasswordSection = !this.changePasswordSection;
  }

  goBack() {
    this.routerLocation.back();
  }

  closeAlert() {
    this.error = null;
  }

  get form() {
    return this.userForm.controls;
  }

  private initForm(user: User) {


    this.roles.forEach(value => {
      if (value.role.toString() === user.role) {
        this.roleName = value.name;
        this.role = value.role;
      }
    });
    if (user.technology) {
      if (user.technology === 'NOT_APP') {
        this.technologyName = "N/A"
      } else {
        this.technologyName = user.technology.charAt(0) + user.technology.substr(1).toLowerCase();
      }
    }
    const badgeNumberValidator = this.role === Role.TM || (!this.role && user.role === Role.ADMIN) ? null : Validators.required;
    const disabled = !this.isAuthorizedToEdit();
    this.userForm = new FormGroup({
      fullName: new FormControl({value:user.name, disabled}, Validators.required),
      username: new FormControl({value:user.username, disabled}, Validators.required),
      badgeNumber: new FormControl({value:user.badgeId, disabled}, badgeNumberValidator),
      oldPassword: new FormControl(''),
      password: new FormControl(''),
      confirmPassword: new FormControl('')
    });
  }

  onSubmit() {
    const formData = new FormData();

    let username = this.form.username.value;
    let name = this.form.fullName.value;
    let badgeId = this.form.badgeNumber.value;
    let password = this.form.password.value;
    let confirmPassword = this.form.confirmPassword.value;
    let oldPassword = this.form.oldPassword.value;


    if (password.length > 0 || confirmPassword.length > 0) {
      if (password !== confirmPassword) {
        this.error = "Passwords don't much";
        return;
      } else if (this.userId == this.currentUser.id && oldPassword.length < 1) {
        this.error = "Old password is required field";
        return;
      }
    }

    const role = this.user.role == "ADMIN" ? Role.ADMIN : this.role;

    const user = new User();
    user.setUserFields({id: this.user.id, username: username, name: name, badgeId: badgeId, password: password,
      townId: this.townId, confirmPassword: confirmPassword, enabled: this.user.enabled, oldPassword: oldPassword,
      technology: this.technologyName, role: role});


    formData.append("user", JSON.stringify(user));
    this.userService.updateUser(formData).subscribe(response => {
        this.user = response;
        this.form.badgeNumber.setValue(this.user.badgeId);
        this.toastService.show('User has been successfully updated',
          { classname: 'bg-success text-light', autohide: true, delay: 2000});
        this.error = '';
      },
      error => {
        this.error = error;
      });
  }

  roleIsEmpty() {
    return (!this.roleName || this.roleName.length === 0 );
  }

  technologyIsEmpty() {
    return (!this.technologyName || this.technologyName.length === 0 );
  }

  get availableRoles() {
    return this.roles.filter(({role}) => this.currentUser.role != role);
  }

  isAllowedToToggleUserStatus() {
    const loggedInUser = this.authenticationService.userValue;
    return this.user && this.user.id != this.currentUser.id && (loggedInUser.role === Role.ADMIN || this.user.role !== Role.TM);
  }

  isAuthorizedToEdit() {
    const loggedInUser = this.authenticationService.userValue;
    const isTM = loggedInUser.role === Role.TM;
    return loggedInUser.role === Role.ADMIN || (isTM && this.user.role !== Role.TM) || (isTM && this.user.id === loggedInUser.id);
  }
}
