<div class="container ticket-details">
  <div class="row ticket-details__row">
    <div class="col-12 col-md-2 back-to-all-tickets">
      <button type="button" class="btn back-to-all-tickets__btn" routerLink="../">
        <i class="bi bi-arrow-left-short back-to-all-tickets__icon"></i>
        <span class="back-to-all-tickets__label">
          <span class="back-to-all-tickets__label-desktop">All</span> Tickets
        </span>
      </button>
    </div>
    <div class="col-12 col-md-10 col-lg-7 ticket-details__wrapper">
      <div class="ticket-details__header">
        <h2 *ngIf="violationRecord">{{ violationRecord.serialNumber }}</h2>
      </div>

      <form [formGroup]="violationRecordForm" *ngIf="violationRecordForm" class="ticket-details__form" id="user-form">
        <div class="form-group row">
          <label for="serialNumber" class="col-md-4 col-form-label">Serial number</label>
          <div class="col-md-8">
            <input type="text" formControlName="serialNumber" name="serialNumber" class="form-control" id="serialNumber" readonly>
          </div>
        </div>
        <div class="form-group row">
          <label for="plateNumber" class="col-md-4 col-form-label">Plate number</label>
          <div class="col-md-8">
            <input type="text" formControlName="plateNumber" class="form-control" id="plateNumber" readonly>
          </div>
        </div>
        <div class="form-group row">
          <label for="date" class="col-md-4 col-form-label">Date</label>
          <div class="col-md-8">
            <input type="text" formControlName="date" class="form-control" id="date" readonly>
          </div>
        </div>
        <div *ngIf="violationRecord.time" class="form-group row">
          <label for="time" class="col-md-4 col-form-label">Time</label>
          <div class="col-md-8">
            <input type="text" formControlName="time" class="form-control" id="time" readonly>
          </div>
        </div>
        <div *ngIf="violationRecord.startTime" class="form-group row">
          <label for="startTime" class="col-md-4 col-form-label">Start time</label>
          <div class="col-md-8">
            <input type="text" formControlName="startTime" class="form-control" id="startTime" readonly>
          </div>
        </div>
        <div class="form-group row">
          <label for="state" class="col-md-4 col-form-label">State</label>
          <div class="col-md-8">
            <input type="text" formControlName="state" class="form-control" id="state" readonly>
          </div>
        </div>
        <div class="form-group row">
          <label for="plateType" class="col-md-4 col-form-label">Plate type</label>
          <div class="col-md-8">
            <input type="text" formControlName="plateType" class="form-control" id="plateType" readonly>
          </div>
        </div>
        <div class="form-group row">
          <label for="plateColor" class="col-md-4 col-form-label">Plate color</label>
          <div class="col-md-8">
            <input type="text" formControlName="plateColor" class="form-control" id="plateColor" readonly>
          </div>
        </div>
        <div class="form-group row">
          <label for="make" class="col-md-4 col-form-label">Make</label>
          <div class="col-md-8">
            <input type="text" formControlName="make" class="form-control" id="make" readonly>
          </div>
        </div>
        <div class="form-group row">
          <label for="color" class="col-md-4 col-form-label">Color</label>
          <div class="col-md-8">
            <input type="text" formControlName="color" class="form-control" id="color" readonly>
          </div>
        </div>
        <div class="form-group row">
          <label for="location" class="col-md-4 col-form-label">Location</label>
          <div class="col-md-8">
            <input type="text" formControlName="location" class="form-control" id="location" readonly>
          </div>
        </div>
        <div class="form-group row">
          <label for="meterNumber" class="col-md-4 col-form-label">Meter number</label>
          <div class="col-md-8">
            <input type="text" formControlName="meterNumber" class="form-control" id="meterNumber" readonly>
          </div>
        </div>
        <div class="form-group row violation">
          <label class="col-md-4 col-form-label">Violations</label>
          <ul *ngIf="violationRecord" class="col-md-8 violation__list">
            <li class="violation__item" *ngFor="let violation of violationRecord.violationTypes">
              <i class="bi bi-check-square-fill violation__icon"></i>
              <span>{{violation.violationTypeCode}} - {{violation.violationTypeName}} <span *ngIf="isDescriptionForOtherViolationAvailable(violation)">({{violation.descriptionForOtherOption}})</span></span>
            </li>
          </ul>
        </div>
        <div class="form-group row">
          <label class="col-md-4 col-form-label">Notes</label>
          <div class="col-md-8">
            <textarea formControlName="notes" readonly></textarea>
          </div>
        </div>

        <div class="form-group row photos">
          <label class="col-md-4 col-form-label">Attached media</label>
          <div class="col-md-8">
            <div class="row">
              <div *ngFor="let ad of violationRecord.accessoryData" class="col-6 photos__wrapper">
                <img *ngIf="ad.type === 'image'" [src]="ad.id + '?townId=' + townId | secure_image | async" class="photos__img" height="200">
                <video *ngIf="ad.type === 'video'" [src]="ad.id + '?townId=' + townId | secure_image | async" height="200" controls></video>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-4 col-form-label">Additional notes</label>
          <div class="col-md-8">
            <textarea formControlName="additionalNotes" readonly></textarea>
          </div>
        </div>
        <div class="form-group row">
          <label for="badgeId" class="col-md-4 col-form-label">Badge ID</label>
          <div class="col-md-8">
            <input type="text" formControlName="badgeId" class="form-control" id="badgeId" readonly>
          </div>
        </div>
        <div class="form-group row">
          <label for="name" class="col-md-4 col-form-label">Name</label>
          <div class="col-md-8">
            <input type="text" formControlName="name" class="form-control" id="name" readonly>
          </div>
        </div>
      </form>

      <div class="ticket-details__btn-group">
        <div class="ticket-details__btn-wrapper">
          <button class="btn btn-dark" (click)="exportAccessoryData()">Export accessory data</button>
          <button class="btn ticket-details__btn-cancel ml-auto" (click)="goBack()">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</div>
