import {Injectable} from "@angular/core";
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {ITown} from "../models/town";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class TownService {

  constructor(private http: HttpClient) {
  }

  getTownById(id: number): Observable<ITown> {
    return this.http.get<ITown>(`${environment.apiUrl}/towns/get/` + id);
  }

  getAllTowns(page: number): any {
    return this.http.get(`${environment.apiUrl}/towns/all?page=` + page);
  }

  saveTown(requestBody: object): Observable<any> {
    return this.http.post<ITown>(`${environment.apiUrl}/towns/update`, requestBody);
  }

  updateTown(requestBody: object): Observable<any> {
    return this.http.put<ITown>(`${environment.apiUrl}/towns/update`, requestBody);
  }

  changeTownStatus(id: number, status: string): Observable<any> {
    const formData = new FormData();
    formData.append('status', status);
    return this.http.put(`${environment.apiUrl}/towns/status/` + id, formData);
  }

}
