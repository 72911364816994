export interface IViolationType {
  id?: number;
  violationTypeCode?: string;
  violationTypeName?: string;
  violationTypeFine?: string;
  descriptionForOtherOption?: string;
  enabled?: boolean;
  townId?: number;
}

export class ViolationType implements IViolationType {

  constructor(
    public id?: number,
    public violationTypeCode?: string,
    public violationTypeName?: string,
    public violationTypeFine?: string,
    public descriptionForOtherOption?: string,
    public enabled?: boolean,
    public townId?: number
  ) {
    this.id = id ? id : 0;
    this.violationTypeCode = violationTypeCode ? violationTypeCode : '';
    this.violationTypeName = violationTypeName ? violationTypeName : '';
    this.violationTypeFine = violationTypeFine ? violationTypeFine : '';
    this.descriptionForOtherOption = descriptionForOtherOption ? descriptionForOtherOption : '';
    this.enabled = enabled || false;
    this.townId = townId ? townId : 0;
  }


  public setViolationFields(state: IViolationType) {
    this.id = state.id ? state.id : this.id;
    this.violationTypeCode = state.violationTypeCode ? state.violationTypeCode : this.violationTypeCode;
    this.violationTypeName = state.violationTypeName ? state.violationTypeName : this.violationTypeName;
    this.violationTypeFine = state.violationTypeFine ? state.violationTypeFine : this.violationTypeFine;
    this.descriptionForOtherOption =
      state.descriptionForOtherOption ? state.descriptionForOtherOption : this.descriptionForOtherOption;
    this.townId = state.townId ? state.townId : this.townId;
    this.enabled = state.enabled || this.enabled;

  }

}


