import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AuthenticationService} from '../services/auth/authentication.service';
import {Role} from "../models/role";

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private authenticationService: AuthenticationService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const user = this.authenticationService.userValue;
    if (user) {
      if (user.role === Role.ADMIN) {
        this.router.navigate(['/dashboard']);
      }
      if (user.role === Role.TM && user.townId) {
          this.router.navigate(['/dashboard/towns', user.townId]);
      }
      // check if route is restricted by role
      if (route.data.roles && route.data.roles.indexOf(user.role) === -1) {
        // role not authorised so redirect to home page
        // this.router.navigate(['/']);
        return false;
      }
      const now = Date.now();
      if (user.expiresAt < now) {
        return this.authenticationService.refresh();
      }

      // authorised so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login']);
    return false;
  }

}
