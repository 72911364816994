<div class="dashboard" [ngClass]="{blur: isMenuOpened}">
  <div class="container-fluid dashboard__wrapper">
    <div class="row menu__container dashboard__header justify-content-between">
      <div class="menu__btn menu__btn-mobile" (click)="isMenuOpened = !isMenuOpened">
        <i class="bi bi-list"></i>
      </div>
      <div class="account">
        <div class="user" (click)="showDropdown()">
          <div class="user__name d-none d-sm-block">
            {{userName}}
          </div>
          <div class="user__img" *ngIf="userName" #userSelectButton>
            <div class="user__initials">{{initials | uppercase}}</div>
          </div>
        </div>
        <div *ngIf="contentUserDropdown" class="dropdown-menu-right" aria-labelledby="profileDropdown" #userContent>
          <button *ngIf="user && user.role !== 'ADMIN'" class="btn btn-edit"
                  [routerLink]="['towns', townId, 'users', user.id]">Edit Profile
          </button>
          <button *ngIf="user && user.role === 'ADMIN'" class="btn btn-edit"
                  [routerLink]="['towns', 0, 'users', user.id]">Edit Profile
          </button>
          <button (click)="logout()" class="btn btn-logout">Logout</button>
        </div>
      </div>
    </div>
    <div class="menu" *ngIf="town"
         [ngClass]="{opened: isMenuOpened}">
      <div class="menu__wrapper">
        <ul>
          <li class="menu__header row no-gutters justify-content-between">
            <span>Admin Panel</span>
            <button (click)="isMenuOpened = !isMenuOpened" class="btn menu__btn-close"><i class="bi bi-x"></i></button>
          </li>
          <li class="menu__btn menu__btn-tablet" (click)="isMenuOpened = !isMenuOpened">
            <i class="bi bi-list"></i>
          </li>
          <li *ngIf="isAdmin()">
            <a routerLink="/dashboard"
               class="go-back__link">
              <i class="bi bi-arrow-left-short go-back__icon"></i>
              <span class="go-back__label">Back to Towns</span>
            </a>
          </li>
          <li class="town-info" *ngIf="town">
            <ul class="town-info__list">
              <li class="town-info__item town-info__item-img"><img src="{{logoUrl}}" alt="{{town.name}}"></li>
            </ul>
          </li>
          <li class="menu__item">
            <a [routerLink]="['towns', townId, 'edit']"
               class="menu__link"
               routerLinkActive="active">
              <i class="bi bi-pencil-square"></i>
              <span class="menu__label">Edit</span>
            </a>
          </li>
          <li class="menu__item">
            <a [routerLink]="['towns', townId, 'users']"
               class="menu__link"
               routerLinkActive="active"
               #user=routerLinkActive>
              <i class="bi bi-people{{user.isActive ? '-fill' : ''}}"></i>
              <span class="menu__label">Users</span>
            </a>
          </li>
          <li class="menu__item">
            <a [routerLink]="['towns', townId, 'violations']"
               routerLinkActive="active"
               class="menu__link"
               #violation=routerLinkActive>
              <i class="{{violation.isActive ? 'bi-shield-fill-exclamation' : 'bi bi-shield-exclamation'}}"></i>
              <span class="menu__label">Violations & Fees</span>
            </a>
          </li>
          <li class="menu__item">
            <a [routerLink]="['towns', townId, 'tickets']"
               routerLinkActive="active"
               class="menu__link"
               #ticket=routerLinkActive>
              <i class="bi bi-file-earmark-text{{ticket.isActive ? '-fill' : ''}}"></i>
              <span class="menu__label">Tickets</span>
            </a>
          </li>
          <li class="menu__item" *ngIf="isAdmin()">
            <a [routerLink]="['towns', townId, 'email-management']"
               routerLinkActive="active"
               class="menu__link"
               #emailManagement=routerLinkActive>
              <i class="bi bi-envelope{{emailManagement.isActive ? '-fill' : ''}}"></i>
              <span class="menu__label">Email Management</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <router-outlet></router-outlet>
</div>

