<div class="container violation-details">
  <div class="row violation-details__row">
    <div class="col-12 col-md-2 back-to-all-violations">
      <button type="button" class="btn back-to-all-violations__btn" routerLink="../">
        <i class="bi bi-arrow-left-short back-to-all-violations__icon"></i>
        <span class="back-to-all-violations__label">
          <span class="back-to-all-violations__label-desktop">All</span> violations
        </span>
      </button>
    </div>
    <div class="col-12 col-md-10 col-lg-7 violation-details__wrapper">
      <div *ngIf="violation" class="violation-details__header">
        <h2>{{ violation.violationTypeCode }}</h2>
        <span class="custom-control custom-switch">
              <input [(ngModel)]="violation.enabled" [checked]="violation.enabled" type="checkbox" class="custom-control-input" id="violationStatus">
              <label class="custom-control-label" for="violationStatus"></label>
        </span>
        <span class="custom-control-span">{{violation.enabled ? 'Enable' : 'Disable'}}</span>
      </div>
      <ngb-alert *ngIf="error" (close)="closeAlert()" [type]="'danger'">{{ error }}</ngb-alert>
      <form *ngIf="violation" [formGroup]="violationForm" class="violation-details__form" id="violation-details-form">
        <div class="form-group row">
          <label for="code" class="col-md-4 col-form-label">Violation code</label>
          <div class="col-md-8">
            <input formControlName="code" name="code"  type="text" class="form-control" id="code">
          </div>
        </div>
        <div class="form-group row">
          <label for="fee" class="col-md-4 col-form-label">Fee</label>
          <div class="col-md-8">
            <input formControlName="fee" type="text" name="fee" class="form-control" id="fee">
          </div>
        </div>
        <div class="form-group row">
          <label for="violation" class="col-md-4 col-form-label">Violation</label>
          <div id="violation" class="col-md-8">
            <textarea name="violation" formControlName="violation" ></textarea>
          </div>
        </div>
      </form>
      <div class="violation-details__btn-group">
        <div class="violation-details__btn-wrapper">
          <button [disabled]="violation && violationForm.invalid" [class.active]="violation && !violationForm.invalid" (click)="onSubmit()"
                  class="btn violation-details__btn-save">Save changes</button>
          <button (click)="goBack()" class="btn violation-details__btn-cancel">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</div>
<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
