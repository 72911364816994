<div class="container town-account">
  <div class="row town-account__row">
    <div class="col-12 col-md-2 back-to-all-towns">
      <button type="button" class="btn back-to-all-towns__btn" routerLink="/dashboard">
        <i class="bi bi-arrow-left-short back-to-all-towns__icon"></i>
        <span class="back-to-all-towns__label">
          <span class="back-to-all-towns__label-desktop">{{isAdmin() ? 'All Towns' : 'Back'}}</span>
        </span>
      </button>
    </div>
    <div class="col-12 col-md-10 col-lg-7 town-account__wrapper">
      <div class="town-account__header" *ngIf="town">
        <h2>{{town.name}}</h2>
        <ngb-alert *ngIf="error" (close)="closeAlert()" [type]="'danger'">{{ error }}</ngb-alert>
        <span class="custom-control custom-switch">
          <input checked type="checkbox" class="custom-control-input" [(ngModel)]="enable" id="enable" [disabled]="!isAdmin()">
          <label class="custom-control-label" for="enable"></label>
          <span class="custom-control-span">{{enable ? 'Enable' : 'Disable'}}</span>
        </span>
      </div>
      <form [formGroup]="townForm" *ngIf="townForm" (ngSubmit)="onSubmit()">
        <div class="form-group row">
          <label for="townName" class="col-md-4 col-form-label">Town name</label>
          <div class="col-md-8">
            <input type="text" class="form-control" formControlName="townName" id="townName">
          </div>
        </div>
        <div class="form-group row">
          <label for="townFormalName" class="col-md-4 col-form-label">Town Formal Name</label>
          <div class="col-md-8">
            <input type="text" class="form-control" formControlName="townFormalName" id="townFormalName">
          </div>
        </div>
        <div class="form-group row">
          <label for="townCode" class="col-md-4 col-form-label">Town Code</label>
          <div class="col-md-8">
            <input type="text" class="form-control" id="townCode" formControlName="townCode">
          </div>
        </div>
        <div class="form-group row">
          <label for="series" class="col-md-4 col-form-label">Series</label>
          <div class="col-md-8">
            <input type="text" class="form-control" id="series" formControlName="series">
            <small class="form-text" *ngIf="form.series.errors?.pattern" [class.required]="form.series.errors?.pattern">This field must be numeric</small>
            <small class="form-text" *ngIf="form.series.errors?.min" [class.required]="form.series.errors?.min">This field must be greater than 0</small>
            <small class="form-text" *ngIf="form.series.errors?.max" [class.required]="form.series.errors?.max">This field must be lower than 10</small>
          </div>
        </div>
        <div class="form-group row">
          <label for="ticketStart" class="col-md-4 col-form-label">Ticket start</label>
          <div class="col-md-8">
            <input type="text" class="form-control" id="ticketStart" formControlName="ticketStart">
            <small class="form-text" *ngIf="form.ticketStart.errors?.pattern" [class.required]="form.ticketStart.errors?.pattern">This field must be numeric</small>
            <small class="form-text" *ngIf="form.ticketStart.errors?.min" [class.required]="form.ticketStart.errors?.min">This field must be greater than or equal to 0</small>
            <small class="form-text" *ngIf="form.ticketStart.errors?.maxlength" [class.required]="form.ticketStart.errors?.maxlength">The length of this field must be up to 8 characters</small>
          </div>
        </div>
        <div class="form-group row">
          <label for="townLogo" class="col-md-4 col-form-label">Town logo</label>
          <div class="col-md-8 logo-upload">
            <div class="logo-upload-wrap">
              <img [src]="imgURL" alt=""/>
            </div>
            <span class="btn btn-dark file-input-custom">
              <input type="file" accept='image/*' (change)="onFileChange($event)"
                     formControlName="townLogo" id="townLogo"/>
            Change logo
          </span>
          </div>
        </div>
        <div class="form-group row town-admin" *ngIf="isAdmin() && users && users.length > 0">
          <label class="col-md-4 col-form-label">Town Admin</label>
          <div class="col-md-8 select-container" formGroupName="townManagersCheckboxGroup">
            <div class="main-select manager-select form-control"
                 [class.warning]="form.townManagersCheckboxGroup.errors?.requireCheckboxesToBeChecked"
                 (click)="toggleAdminDropdown()">
              <div class="main-select__wrapper">
                <ng-container *ngFor="let user of users">
                  <span class="town-admin__name" *ngIf="user.role === 'TM'"> {{user.name}}
                    <i (click)="unselectUser(user.id, $event)" class="bi bi-x town-admin__icon"></i></span>
                </ng-container>
              </div>
            </div>
            <div class="select-content form-control form-control-lg" *ngIf="contentAdminDropdown">
              <ul class="option-list">
                <li class="search option-item">
                  <form class="form-inline" autocomplete="off">
                    <div class="input-group search__group">
                      <div class="input-group-prepend">
                        <span class="input-group-text search__icon"><i class="bi bi-search"></i></span>
                      </div>
                      <input class="form-control search__input" name="userFilter" type="search" [(ngModel)]="userFilter"
                             placeholder="Search Town Admin" aria-label="Search">
                    </div>
                  </form>
                </li>
                <ng-container *ngFor="let user of filteredUsers">
                  <li class="option-item">
                    <input formControlName="{{user.id}}" type="checkbox" id="{{user.id}}">
                    <label for="{{user.id}}" class="town-admin__label">{{user.name}}</label>
                  </li>
                </ng-container>
              </ul>
            </div>
            <small class="form-text"
                   [class.text-muted]="!form.townManagersCheckboxGroup.errors?.requireCheckboxesToBeChecked"
                   [class.required]="form.townManagersCheckboxGroup.errors?.requireCheckboxesToBeChecked">You can select
              at least 1 and at most 5 town managers</small>
          </div>
        </div>
        <div class="form-group row">
          <label *ngIf="town.name == 'Wellesley'" class="col-md-4 col-form-label">Passport kiosk</label>
          <label *ngIf="town.name != 'Wellesley'" class="col-md-4 col-form-label">Flowbird kiosk</label>
          <div class="col-md-8 form-check">
            <div class="form-check form-check-inline">
              <input class="form-check-input"
                     type="radio"
                     name="kiosk"
                     id="yes"
                     formControlName="kiosk"
                     value="yes" checked>
              <label class="form-check-label" for="yes">
                Yes
              </label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input"
                     type="radio"
                     name="kiosk"
                     id="no"
                     formControlName="kiosk"
                     value="no">
              <label class="form-check-label" for="no">
                No
              </label>
            </div>
          </div>
        </div>
        <end-text-component [form]="townForm" [editorDisabled]="!isAdmin()"></end-text-component>
        <div class="town-account__btn-group">
          <div class="town-account__btn-wrapper">
            <button [disabled]="townForm.invalid" [class.active]="!townForm.invalid" type="submit"
                    class="btn town-account__btn-save">Save changes</button>
            <button (click)="goBack()" class="btn town-account__btn-cancel">Cancel</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>


