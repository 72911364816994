<div class="container users">
  <div class="users__header row no-gutters justify-content-between">
    <div class="users-add">
      <span class="users-add__title">Users <span class="users-add__count">({{users.length}})</span></span>
      <button type="button" class="btn users-add__btn"
              (click)="openBackDropCustomClass(content)">
        <i class="bi bi-plus users-add__btn-icon"></i>
        <span class="users-add__btn-label">Add new user</span>
      </button>
    </div>
    <div class="print">
      <button (click)="printPEAChart()" class="btn btn-print">
        <i class="bi bi-printer"></i>
        <span class="btn-print__label">Print chart</span>
      </button>
    </div>
  </div>

  <div class="table">
    <ul class="table__list">
      <li class="table__header d-none d-md-block">
        <ul class="header__list">
          <li class="header__item header__item-full-name">Full Name</li>
          <li class="header__item header__item-user-name">User Name</li>
          <li class="header__item header__item-badge">Badge ID</li>
          <li class="header__item header__item-role">Role</li>
          <li class="header__item header__item-mobile header__item-technology">Tech</li>
          <li class="header__item header__item-desktop header__item-technology">Technology</li>
          <li class="header__item header__item-status">Status</li>
        </ul>
      </li>
      <li class="table__item" *ngFor="let user of users; index as i">
        <ul class="info">
          <li class="info__item info__item-full-name">
            <span class="info__field-name d-md-none">Full Name</span>
            <a [routerLink]="[user.id]">{{user.name}}</a>
          </li>
          <li class="info__item info__item-user-name">
            <span class="info__field-name d-md-none">User Name</span>
            <span>{{user.username}}</span>
          </li>
          <li class="info__item info__item-badge">
            <span class="info__field-name d-md-none">Badge ID</span>
            <span>{{user.badgeId}}</span>
          </li>
          <li class="info__item info__item-role">
            <span class="info__field-name d-md-none">Role</span>
            <span class="info__label-desktop" *ngIf="user.role == 'PEA'">Parking Enforcement</span>
            <span class="info__label-desktop" *ngIf="user.role == 'TM'">Town Admin</span>
            <span class="info__label-desktop" *ngIf="user.role == 'LEO'">Law Enforcement</span>
            <span class="info__label-tablet">{{user.role}}</span>
          </li>
          <li class="info__item info__item-technology">
            <span class="info__field-name d-md-none">Tech</span>
            <span class="info__label-desktop">{{user.technology === 'NOT_APP' ? 'N/A' : user.technology}}</span>
            <span class="info__label-tablet" *ngIf="user.technology == 'Tablet'"><i class="bi bi-tablet"></i></span>
            <span class="info__label-tablet" *ngIf="user.technology == 'Laptop'"><i class="bi bi-laptop"></i></span>
            <span class="info__label-tablet" *ngIf="user.technology == 'NOT_APP'">{{user.technology}}</span>
          </li>
          <li class="info__item info__item-enable">
            <span class="info__field-name d-md-none">Status</span>
            <span class="custom-control-span custom-control-span-desktop">{{user.enabled? 'Enable' : 'Disable'}}</span>
            <span class="custom-control custom-switch">
              <input (click)="changeUserStatus(user.id, !user.enabled)" [checked]="user.enabled" type="checkbox" class="custom-control-input" id="{{user.id}}">
              <label class="custom-control-label" for="{{user.id}}"></label>
            </span>
            <span class="custom-control-span custom-control-span-mobile">{{user.enabled? 'Enable' : 'Disable'}}</span>
          </li>
        </ul>
      </li>
    </ul>
  </div>

  <div class="table__pagination">
    <ngb-pagination class="d-flex justify-content-end" (pageChange)="onPageChange($event)" [collectionSize]="totalElements" [pageSize]="size" [(page)]="currentPage" aria-label="Town pagination">
      <ng-template ngbPaginationPrevious>Prev</ng-template>
      <ng-template ngbPaginationNext>Next</ng-template>
    </ngb-pagination>
  </div>

  <app-toasts aria-live="polite" aria-atomic="true"></app-toasts>

  <ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title">New User Account</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <i class="bi bi-x" aria-hidden="true"></i>
      </button>
    </div>
    <div class="modal-body">
      <ngb-alert *ngIf="error" (close)="closeAlert()" [type]="'danger'">{{ error }}</ngb-alert>
      <form class="add-new-user" [formGroup]="userForm">
        <div class="form-group row">
          <div class="col-12 col-md-6">
            <label class="is-required"
                   for="fullName">Full name</label>
            <input type="text"
                   placeholder="Full name"
                   name="fullName"
                   formControlName="fullName"
                   class="form-control"
                   id="fullName">
          </div>
          <div class="col-12 col-md-6">
            <label class="is-required"
                   for="username">Username</label>
            <input type="text"
                   placeholder="Username"
                   name="username"
                   formControlName="username"
                   class="form-control"
                   id="username">
          </div>
        </div>
        <div class="form-group row">
        <div class="col-12 col-md-6 add-new-town__name">
          <label class="is-required"
                 for="fullName">Role</label>
          <div class="select-container">
            <div #roleSelectButton class="main-select form-control form-control" (click)="toggleRoleDropdown()">
              <span class="state-name">{{roleName}}</span>
            </div>
            <div #roleContent class="select-content form-control form-control"
                 *ngIf="contentRoleDropdown">
              <ul class="option-list">
                <li class="option-item"
                    *ngFor="let role of availableRoles"
                    (click)="selectRole(role)">
                  <span class="state-name">{{role.name}}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6" *ngIf="role !== 'TM'">
          <label class="is-required"
                 for="badgeNumber">Badge number</label>
          <input type="text"
                 placeholder="Badge number"
                 name="badgeNumber"
                 formControlName="badgeNumber"
                 class="form-control"
                 id="badgeNumber">
        </div>
      </div>
        <div class="form-group row">
          <div class="col-12 col-md-6 add-new-town__name">
            <label class="is-required"
                   for="fullName">Technology</label>
            <div class="select-container">
              <div #technologySelectButton class="main-select form-control form-control" (click)="toggleTechnologyDropdown()">
                <span class="state-name">{{technologyName}}</span>
              </div>
              <div #technologyContent class="select-content form-control form-control"
                   *ngIf="contentTechnologyDropdown">
                <ul class="option-list">
                  <li class="option-item"
                      *ngFor="let technology of technologies"
                      (click)="selectTechnology(technology)">
                    <span class="state-name">{{technology}}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-12 col-md-6 add-new-town__name">
            <label class="is-required"
                   for="password">Password</label>
            <input type="password"
                   placeholder="Password"
                   name="password"
                   formControlName="password"
                   class="form-control"
                   id="password">
          </div>
          <div class="col-12 col-md-6">
            <label class="is-required"
                   for="confirmPassword">Confirm Password</label>
            <input type="password"
                   placeholder="Confirm Password"
                   name="confirmPassword"
                   formControlName="confirmPassword"
                   class="form-control"
                   id="confirmPassword">
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-cancel" (click)="closeModal()">Cancel</button>
      <button [disabled]="userForm.invalid || roleIsEmpty() || technologyIsEmpty()"
              [class.active]="!userForm.invalid && !roleIsEmpty() && !technologyIsEmpty()"
        type="button" class="btn btn-dark create-account" (click)="onSubmit()">Create account</button>
    </div>
  </ng-template>

  <ng-template #contentSaveChanges let-modal>
    <div class="modal-body">
      <p>Do you want to save changes before leaving the page?</p>
    </div>
    <div class="modal-footer save__btn-group">
      <button type="button" class="btn save__btn-no" (click)="modal.dismiss()">No</button>
      <button type="button" class="btn save__btn-yes">Yes</button>
    </div>
  </ng-template>
</div>
