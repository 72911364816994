import {AfterViewChecked, ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {AuthenticationService} from '../../services/auth/authentication.service';
import {NavigationService} from "../../services/navigation.service";
import {switchMap} from "rxjs/operators";
import {TownService} from "../../services/town.service";
import {ITown} from "../../models/town";
import {environment} from "../../../environments/environment";
import {EMPTY, Observable} from "rxjs";
import {User} from "../../models/user";
import {Role} from "../../models/role";


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss',
    '../../app.component.scss']
})
export class DashboardComponent implements OnInit, AfterViewChecked {

  isMenuOpened: boolean;
  contentUserDropdown = false;
  townId: number;
  town: ITown;

  user: User;

  @ViewChild('userSelectButton', {static: false}) userSelectButton: ElementRef;
  @ViewChild('userContent', {static: false}) userContent: ElementRef;
  logoUrl: string;

  constructor(private authenticationService: AuthenticationService,
              private renderer: Renderer2,
              private navigationService: NavigationService,
              private cdRef: ChangeDetectorRef,
              private townService: TownService) {
    this.renderer.listen('window', 'click', (e: Event) => {
      const customDropdownElementPropertyNames = [{
        selectButtonName: 'userSelectButton',
        selectContentName: 'userContent',
        selectStateName: 'contentUserDropdown'
      }];
      this.handleDropdownOnClick(e, customDropdownElementPropertyNames);
    });
  }

  handleDropdownOnClick(e: Event, customDropdownElementPropertyNames: any[]): void {
    for (const customDropdownElementPropertyName of customDropdownElementPropertyNames) {
      const {selectButtonName, selectContentName, selectStateName} = customDropdownElementPropertyName;
      if (e.target !== this[selectButtonName]?.nativeElement && e.target !== this[selectContentName]?.nativeElement && (e.target as HTMLElement).parentElement !== this[selectButtonName]?.nativeElement) {
        this[selectStateName] = false;
      }
    }
  }

  ngOnInit(): void {
    this.user = this.authenticationService.userValue;

    if (window.innerWidth > 1200) {
      this.isMenuOpened = true;
    }

    this.navigationService.changeEmitted$.pipe(
      switchMap(value => {
        this.town = this.navigationService.townValue || undefined;
        this.logoUrl = this.town ? environment.apiUrl+this.town.logoUrl : undefined;
        this.townId = this.town?.id;
        if (+value && (!this.town || this.town.id !== +value) && +value > 0) {
          this.townId = +value;
          return this.townService.getTownById(this.townId);
        }
        return EMPTY
      })
    ).subscribe(value => {
      this.town = value;
      this.navigationService.setTown(value);
      this.logoUrl = environment.apiUrl + this.town.logoUrl;
    });
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  get initials() {
    const firstAndLastName = this.authenticationService.userValue?.name?.split(' ');
    if (firstAndLastName && firstAndLastName.length > 1) {
      return firstAndLastName[0]?.charAt(0) + firstAndLastName[1]?.charAt(0);
    } else {
      return this.userName?.charAt(0);
    }
  }

  get userName() {
    return this.authenticationService.userValue?.name || this.authenticationService.userValue?.username;
  }

  isAdmin() {
    return this.user.role === Role.ADMIN;
  }

  logout() {
    this.authenticationService.logout();
  }

  showDropdown() {
    this.contentUserDropdown = !this.contentUserDropdown;
  }
}
