<div class="container town-list">
  <div class="dashboard__add-btn">
    <span class="dashboard__name-items">Towns <span class="dashboard__qty-items">({{ totalElements }})</span></span>
    <button type="button" class="btn btn-dark dashboard__btn-dark"
            (click)="openBackDropCustomClass(content)">
      <i class="bi bi-plus"></i><span class="btn-label">Add new town</span></button>
  </div>

  <div class="table">
    <ul class="table__list">
      <li class="table__header d-none d-md-block">
        <ul class="header__list">
          <li class="header__item header__item-logo">Logo</li>
          <li class="header__item header__item-town">Town</li>
          <li class="header__item header__item-code">Town code</li>
          <li class="header__item header__item-series">Series</li>
          <li class="header__item header__item-ticket-start">Ticket start</li>
          <li class="header__item header__item-admin">Town Admin</li>
          <li class="header__item header__item-mobile header__item-kiosk">FB kiosk</li>
          <li class="header__item header__item-desktop header__item-kiosk">Flowbird kiosk</li>
          <li class="header__item header__item-status">Status</li>
        </ul>
      </li>
      <li class="table__item" *ngFor="let town of towns; index as i">
        <ul class="info">
          <li class="info__item info__item-logo">
            <span class="info__field-name d-md-none">Logo</span>
            <img [src]="environment.apiUrl + town.logoUrl">
            <button type="button" class="btn info__btn-edit" [routerLink]="[town.id]"><i
              class="bi bi-arrow-right-short"></i></button>
          </li>
          <li class="info__item info__item-name"><span class="info__field-name d-md-none">Town</span><a
            [routerLink]="[town.id]">{{ town.name }}</a></li>
          <li class="info__item info__item-admin">
            <span class="info__field-name d-md-none">Town Admin</span>
            <ul *ngIf="town.townManagers">
              <li *ngFor="let admin of town.townManagers; index as id">{{admin.name}}</li>
            </ul>
          </li>
          <li class="info__item info__item-code"><span
            class="info__field-name d-md-none">Town code</span>{{ town.townCode }}</li>
          <li class="info__item info__item-series"><span
            class="info__field-name d-md-none">Series</span>{{ town.series }}</li>
          <li class="info__item info__item-ticket-start"><span
            class="info__field-name d-md-none">Ticket start</span>{{ town.ticketStart }}</li>
          <li class="info__item info__item-kiosk"><span
            class="info__field-name d-md-none">FB kiosk</span>{{ town.apiUsed }}</li>
          <li class="info__item info__item-enable">
            <span class="info__field-name d-md-none">Status</span>
            <span class="custom-control-span custom-control-span-desktop">Enable</span>
            <span class="custom-control custom-switch">
              <input (change)="onEnableTownChange($event)" [checked]="town.enabled" type="checkbox" class="custom-control-input" id="{{ town.id }}">
              <label class="custom-control-label" for="{{ town.id }}"></label>
            </span>
            <span class="custom-control-span custom-control-span-mobile">Enable</span>
          </li>
        </ul>
      </li>
    </ul>
  </div>

  <div class="table__pagination">
    <ngb-pagination class="d-flex justify-content-end" (pageChange)="onPageChange($event)" [collectionSize]="totalElements" [pageSize]="size" [(page)]="currentPage" aria-label="Town pagination">
      <ng-template ngbPaginationPrevious>Prev</ng-template>
      <ng-template ngbPaginationNext>Next</ng-template>
    </ngb-pagination>
  </div>
</div>
<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title">New Town</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <i class="bi bi-x" aria-hidden="true"></i>
    </button>
  </div>
  <div class="modal-body">
    <ngb-alert *ngIf="error" (close)="closeAlert()" [type]="'danger'">{{ error }}</ngb-alert>
    <form class="add-new-town" [formGroup]="townForm">
      <div class="form-group row">
        <div class="col-12 col-xl-6 add-new-town__name">
          <label class="is-required"
                 for="townName">Town name</label>
          <input type="text"
                 placeholder="Town name"
                 name="townName"
                 formControlName="townName"
                 class="form-control"
                 id="townName">
        </div>
        <div class="col-12 col-xl-6">
          <label class="is-required"
                 for="townCode">Town code</label>
          <input type="text"
                 placeholder="Town code"
                 name="townCode"
                 formControlName="townCode"
                 class="form-control"
                 id="townCode">
        </div>
      </div>
      <div class="form-group row">
        <div class="col-12 col-xl-6 add-new-town__name">
          <label for="townFormalName">Town Formal Name</label>
          <input type="text"
                 placeholder="Town Formal Name"
                 name="townFormalName"
                 formControlName="townFormalName"
                 class="form-control"
                 id="townFormalName">
        </div>
      </div>
      <div class="form-group row">
        <div class="col-12 col-xl-6 add-new-town__name">
          <label class="is-required"
                 for="series">Series</label>
          <input type="text"
                 placeholder="Series"
                 name="series"
                 formControlName="series"
                 class="form-control"
                 id="series">
          <small class="form-text" *ngIf="form.series.errors?.pattern" [class.required]="form.series.errors?.pattern">This field must be numeric</small>
          <small class="form-text" *ngIf="form.series.errors?.min" [class.required]="form.series.errors?.min">This field must be greater than 0</small>
          <small class="form-text" *ngIf="form.series.errors?.max" [class.required]="form.series.errors?.max">This field must be lower than 10</small>
        </div>
        <div class="col-12 col-xl-6">
          <label class="is-required"
                 for="ticketStart">Ticket start</label>
          <input type="text"
                 placeholder="Ticket start"
                 name="ticketStart"
                 formControlName="ticketStart"
                 class="form-control"
                 id="ticketStart">
          <small class="form-text" *ngIf="form.ticketStart.errors?.pattern" [class.required]="form.ticketStart.errors?.pattern">This field must be numeric</small>
          <small class="form-text" *ngIf="form.ticketStart.errors?.min" [class.required]="form.ticketStart.errors?.min">This field must be greater than or equal to 0</small>
          <small class="form-text" *ngIf="form.ticketStart.errors?.maxlength" [class.required]="form.ticketStart.errors?.maxlength">The length of this field must be up to 8 characters</small>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-12">
          <div>
            <label class="is-required"
                   for="townLogo">Town logo</label>
          </div>
          <div class="row no-gutters">
            <span class="btn btn-dark file-input-custom">
              <input type="file" accept='image/*' (change)="onFileChange($event)"
                     formControlName="townLogo" id="townLogo"/>
              Attach logo
            </span>
            <div class="col-8 logo-upload-wrap">
              <img [src]="imgURL" *ngIf="imgURL">
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-12">
          <span class="is-required">Flowbird kiosk</span>
        </div>
        <div class="col-12">
          <div class="form-check form-check-inline">
            <input class="form-check-input"
                   type="radio"
                   name="kiosk"
                   id="yes"
                   formControlName="kiosk"
                   value="yes" checked>
            <label class="form-check-label" for="yes">
              Yes
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input"
                   type="radio"
                   name="kiosk"
                   id="no"
                   formControlName="kiosk"
                   value="no">
            <label class="form-check-label" for="no">
              No
            </label>
          </div>
        </div>
      </div>
      <end-text-component [form]="townForm" [isModal]="true"></end-text-component>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-cancel" (click)="closeModal()">Cancel</button>
    <button [disabled]="townForm.invalid || !isFileSet" [class.active]="!townForm.invalid || isFileSet" type="button"
            class="btn btn-dark create-account" (click)="onSubmit()">Create town
    </button>
  </div>
</ng-template>





