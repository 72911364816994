import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {DailyDigestType} from "../models/daily-digest-type";
import {environment} from "../../environments/environment";
import {Injectable} from "@angular/core";
import {DailyDigestRecipient} from "../models/daily-digest-recipient";

@Injectable({
  providedIn: 'root'
})
export class EmailManagementService {

  constructor(private http: HttpClient) {
  }

  getAllDailyDigestTypes(townId: number): Observable<DailyDigestType[]> {
    return this.http.get<DailyDigestType[]>(`${environment.apiUrl}/email-management/types/${townId}/all`);
  }

  getAllDailyDigestRecipientsByTownId(townId: number, page: number): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/email-management/recipients/${townId}/all?page=${page}`);
  }

  createRecipient(dailyDigestRecipient: any) {
    return this.http.post(`${environment.apiUrl}/email-management/save`, dailyDigestRecipient);
  }

  changeDailyDigestTypeStatusForRecipient(recipientId: number, typeId: number, status: object) {
    return this.http.put(`${environment.apiUrl}/email-management/changeStatus/${recipientId}/${typeId}`, status);
  }

  deleteRecipient(recipientId: number) {
    return this.http.delete(`${environment.apiUrl}/email-management/recipients/delete/${recipientId}/`);
  }

  changeEmailForRecipient(dailyDigestRecipient) {
    return this.http.put(`${environment.apiUrl}/email-management/recipients/changeEmail`, dailyDigestRecipient);
  }
}
