<div class="container email-list">
  <div class="email-list__header row no-gutters justify-content-between">
    <div class="email-list-add">
      <span class="email-list-add__title">Daily Digest Recipients <span
        class="email-list-add__count">({{totalElements || 0}})</span></span>
      <button type="button" class="btn email-list-add__btn"
              (click)="openBackDropCustomClass(content)">
        <i class="bi bi-plus email-list-add__btn-icon"></i>
        <span class="email-list-add__btn-label">Add new recipient</span>
      </button>
    </div>
  </div>

  <div class="table">
    <ul class="table__list">
      <li class="table__header d-none d-md-block">
        <ul class="header__list">
          <li class="header__item header__item-actions">Actions</li>
          <li class="header__item header__item-email">Email</li>
          <li *ngFor="let dailyDigestType of dailyDigestTypes"
              class="header__item header__item-status">{{dailyDigestType.displayName}}</li>
        </ul>
      </li>
      <li class="table__item" *ngFor="let recipient of dailyDigestRecipients; index as i">
        <ul class="info">
          <li class="info__item info__item-actions">
            <span class="info__field-name d-md-none">Actions</span>
            <div role="group">
              <button type="button" class="btn btn-sm btn-outline-dark"
                      (click)="openRecipientEditDialog(recipient)"><i class="bi bi-pencil-square"></i></button>
              <button type="button" class="btn btn-sm btn-outline-danger"
                      (click)="openDeletionConfirmationDialog(recipient.id)"><i class="bi bi-trash"></i></button>
            </div>
          </li>
          <li class="info__item info__item-email">
            <span class="info__field-name d-md-none">Email</span>
            <span>{{recipient.email}}</span>
          </li>
          <li *ngFor="let dailyDigestType of recipient.dailyDigestTypes" class="info__item info__item-enable">
            <span class="info__field-name d-md-none">{{dailyDigestType.displayName}}</span>
            <span class="custom-control custom-switch">
              <input
                (click)="toggleDailyDigestTypeForRecipient(recipient.id, dailyDigestType.id, !dailyDigestType.enabled)"
                [checked]="dailyDigestType.enabled" type="checkbox" class="custom-control-input"
                id="{{recipient.id +'-'+ dailyDigestType.id}}">
              <label class="custom-control-label" for="{{recipient.id +'-'+ dailyDigestType.id}}"></label>
            </span>
          </li>
        </ul>
      </li>
    </ul>
  </div>

  <div class="table__pagination">
    <ngb-pagination class="d-flex justify-content-end" (pageChange)="onPageChange($event)"
                    [collectionSize]="totalElements" [pageSize]="size" [(page)]="currentPage"
                    aria-label="Town pagination">
      <ng-template ngbPaginationPrevious>Prev</ng-template>
      <ng-template ngbPaginationNext>Next</ng-template>
    </ngb-pagination>
  </div>

  <app-toasts aria-live="polite" aria-atomic="true"></app-toasts>

  <ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title">New Daily Digest Recipient</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <i class="bi bi-x" aria-hidden="true"></i>
      </button>
    </div>
    <div class="modal-body">
      <ngb-alert *ngIf="error" (close)="closeAlert()" [type]="'danger'">{{ error }}</ngb-alert>
      <form class="add-new-user" [formGroup]="dailyDigestRecipientForm">
        <div class="form-group">
            <label class="is-required"
                   for="email">Email</label>
            <input type="email"
                   autocomplete="off"
                   placeholder="Email"
                   name="email"
                   formControlName="email"
                   class="form-control"
                   id="email"
                   [ngClass]="{ 'is-invalid': form.email.errors }">
          <div *ngIf="form.email.errors" class="required">
            <div *ngIf="form.email.errors.required">This field is required</div>
            <div *ngIf="form.email.errors.maxlength">This field must be less than 255 characters</div>
            <div *ngIf="form.email.errors.pattern">Entered value doesn't correspond to email pattern</div>
          </div>
        </div>
        <div class="form-group">
          <div class="digest__item" *ngFor="let dailyDigestType of dailyDigestTypes" formGroupName="dailyDigestTypeCheckboxGroup">
            <input type="checkbox"
                  class="digest__input"
                  name="{{dailyDigestType.id}}"
                  formControlName="{{dailyDigestType.id}}"
                  id="{{'ddt-create-'+dailyDigestType.id}}">
            <label for="{{'ddt-create-'+dailyDigestType.id}}"
                  class="digest__label">{{dailyDigestType.displayName}}</label>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-cancel" (click)="closeModal()">Cancel</button>
      <button [disabled]="dailyDigestRecipientForm.invalid"
              [class.active]="!dailyDigestRecipientForm.invalid"
              type="button" class="btn btn-dark create-account" (click)="onSubmit()">Create recipient
      </button>
    </div>
  </ng-template>
</div>




