import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {ViolationService} from "../../../services/violation.service";

@Component({
  selector: 'app-tickets-list',
  templateUrl: './tickets-list.component.html',
  styleUrls: ['../../../app.component.scss', './tickets-list.component.scss']
})
export class TicketsListComponent implements OnInit {

  private routeSubscription: Subscription;

  tickets: any;

  townId = 0;
  currentPage = 0;
  totalElements;
  size;

  searchString: string;

  constructor(private route: ActivatedRoute, private violationService: ViolationService) {
    this.routeSubscription = route.parent.params.subscribe(params => this.townId = params.id);
  }

  ngOnInit(): void {
    this.loadViolations(0, undefined);
  }

  loadViolations(page: number, search: string) {
    this.violationService.getViolationsByTownId(this.townId, page, search).subscribe(value => {
      this.tickets = value.content;
      this.totalElements = value.totalElements;
      this.size = value.size;
    });
  }

  public onPageChange(pageNum: number): void {
    this.currentPage = pageNum - 1;
    this.loadViolations(this.currentPage, this.searchString);
  }

  search() {
    this.loadViolations(this.currentPage, this.searchString);
  }
}
