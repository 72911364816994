import {Component, OnInit} from '@angular/core';
import {ViolationRecord} from '../../models/violation-record';
import {ViolationService} from '../../services/violation.service';
import {ComponentCanDeactivate} from '../../guards/component.can.deactivate';
import {TownService} from "../../services/town.service";
import {environment} from "../../../environments/environment";
import {DomSanitizer} from "@angular/platform-browser";
import {AuthenticationService} from "../../services/auth/authentication.service";
import {Role} from "../../models/role";

@Component({
  selector: 'app-violation-ticket',
  templateUrl: './violation-ticket.component.html',
  styleUrls: ['./violation-ticket.component.css']
})
export class ViolationTicketComponent implements OnInit, ComponentCanDeactivate {

  violationRecord: ViolationRecord;
  printEventFired = false;

  townLogoUrl: any;
  town: any;
  startTime;
  cssUrl = '/assets/violation-ticket-styles-default.css';
  marginBottomClass = 'mb-4';

  constructor(private violationService: ViolationService, private townService: TownService,
              public sanitizer: DomSanitizer, private authenticationService: AuthenticationService) {
  }

  ngOnInit(): void {
    if (this.authenticationService.userValue.role === Role.LEO) {
      this.cssUrl = '/assets/violation-ticket-styles-leo.css';
      this.marginBottomClass = 'mb-2';
    }
    this.violationRecord = this.violationService.getViolationRecord();
    if (this.violationRecord) {
      this.townService.getTownById(this.violationRecord.townId).subscribe(response => {
        this.town = response;
        this.townLogoUrl = environment.apiUrl + response.logoUrl;
      });
    }
  }

  canDeactivate(): boolean {
    if (this.violationRecord) {
      return confirm('Be sure to print the ticket before leaving this screen.');
    } else {
      return true;
    }
  }


  printPage() {
    window.setTimeout('window.print()', 500);
    this.printEventFired = true;
  }
}
