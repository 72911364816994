import {Component, Input, OnInit} from "@angular/core";
import {FormGroup} from "@angular/forms";
import {AngularEditorConfig} from "@kolkov/angular-editor";

@Component({
  selector: 'end-text-component',
  templateUrl: './end-text.component.html',
  styleUrls: ['./end-text.component.css',
    '../../../app.component.scss']
})
export class EndTextComponent implements OnInit {

  @Input()
  form: FormGroup;
  @Input()
  isModal: boolean;
  @Input()
  editorDisabled: boolean;

  file: any;

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: 'p',
    defaultFontName: '',
    defaultFontSize: '',
    sanitize: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['insertVideo', 'insertImage', 'strikeThrough',
        'superscript', 'subscript', 'link', 'unlink',
        'insertHorizontalRule', 'toggleEditorMode', 'fontName', 'heading']
    ]
  };

  ngOnInit(): void {

  }

  townFileChanged(event) {
    this.file = event.target.files[0];
    this.uploadDocument();
    event.target.value = '';
  }

  uploadDocument() {
    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      this.form.get('htmlContent').setValue(fileReader.result);
    };
    fileReader.readAsText(this.file);
  }
}
