<div class="container tickets">
  <div class="tickets__header row no-gutters justify-content-between">
    <div class="tickets-add">
      <span class="tickets-add__title">Tickets <span class="tickets-add__count">({{ totalElements }})</span></span>
    </div>
    <div class="search">
      <form class="form-inline">
        <div class="input-group search__group">
          <div class="input-group-prepend">
            <span class="input-group-text search__icon"><i class="bi bi-search"></i></span>
          </div>
          <input class="form-control search__input" name="searchString" [(ngModel)]="searchString" type="search" (keyup.enter)="search()" placeholder="Search license plate number" aria-label="Search">
        </div>
      </form>
    </div>
  </div>

  <div class="table">
    <ul class="table__list">
      <li class="table__header d-none d-md-block">
        <ul class="header__list">
          <li class="header__item header__item-serial-number">Serial number</li>
          <li class="header__item header__item-plate-number">Plate number</li>
          <li class="header__item header__item-date">Date</li>
          <li class="header__item header__item-location">Location</li>
        </ul>
      </li>
      <li class="table__item" *ngFor="let ticket of tickets; index as i">
        <ul class="info">
          <li class="info__item info__item-serial-number">
            <span class="info__field-name d-md-none">Serial number</span>
            <a [routerLink]="[ticket.id]">{{ticket.serialNumber}}</a>
          </li>
          <li class="info__item info__item-plate-number">
            <span class="info__field-name d-md-none">Plate number</span>
            <span>{{ticket.licensePlateNumber}}</span>
          </li>
          <li class="info__item info__item-date">
            <span class="info__field-name d-md-none">Date</span>
            <span>{{ticket.date}}</span>
          </li>
          <li class="info__item info__item-location">
            <span class="info__field-name d-md-none">Location</span>
            <span>{{ticket.location}}</span>
          </li>
        </ul>
      </li>
    </ul>
  </div>
  <div class="table__pagination">
    <ngb-pagination class="d-flex justify-content-end" (pageChange)="onPageChange($event)"
                    [collectionSize]="totalElements"
                    [pageSize]="size"
                    [(page)]="currentPage"
                    [maxSize]="2"
                    [rotate]="true"
                    aria-label="Town pagination">
      <ng-template ngbPaginationPrevious>Prev</ng-template>
      <ng-template ngbPaginationNext>Next</ng-template>
    </ngb-pagination>
  </div>
</div>

