import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {User} from '../../../models/user';
import {Role} from '../../../models/role';
import {requireCheckboxesToBeChecked} from '../../../validators/checkboxes.validator';
import {ActivatedRoute} from '@angular/router';
import {TownService} from "../../../services/town.service";
import {ITown, Town} from "../../../models/town";
import {DomSanitizer} from "@angular/platform-browser";
import {environment} from "../../../../environments/environment";
import {ToastService} from "../../../services/toast-service";
import {UserService} from "../../../services/user.service";
import {forkJoin} from "rxjs";
import {Location} from "@angular/common";
import {NavigationService} from "../../../services/navigation.service";
import {AuthenticationService} from "../../../services/auth/authentication.service";

@Component({
  selector: 'app-town-account',
  templateUrl: './town-account.component.html',
  styleUrls: ['./town-account.component.scss',
    '../../../app.component.scss']
})
export class TownAccountComponent implements OnInit {

  contentAdminDropdown = false;
  id: any;
  logo: any;
  imgURL: any;

  isFileSet: boolean = false;

  townForm: FormGroup;
  enable: boolean;
  error = '';
  users: User[];
  town: ITown;
  userFilter = '';

  constructor(private route: ActivatedRoute, private townService: TownService,
              protected sanitizer: DomSanitizer, private toastService: ToastService,
              private userService: UserService, private routerLocation: Location,
              private navigationService: NavigationService, private authenticationService: AuthenticationService) {
  }

  ngOnInit(): void {
    this.id = this.route.parent.snapshot.paramMap.get('id');
    const allUsersInTown = this.userService.getAllUsersInTown(this.id);
    const townById = this.townService.getTownById(this.id);
    forkJoin([allUsersInTown, townById]).subscribe(results => {
      this.users = results[0].content;
      this.town = results[1];
      this.initForm(this.users, this.town);
    });
  }

  get form() {
    return this.townForm.controls;
  }

  initForm(users: User[], town: ITown) {
    const checkboxGroup = this.initCheckboxGroup(users);
    const kiosk = town.apiUsed ? 'yes' : 'no';
    this.enable = town.enabled;
    this.imgURL = environment.apiUrl + town.logoUrl;
    const disabled = !this.isAdmin();
    this.townForm = new FormGroup({
      townName: new FormControl({value: town.name, disabled}, Validators.required),
      townCode: new FormControl({value: town.townCode, disabled}, Validators.required),
      series: new FormControl({value: town.series, disabled}, [Validators.min(1), Validators.max(9), Validators.pattern("^[0-9]*$"), Validators.required]),
      ticketStart: new FormControl({value: town.ticketStart, disabled},  [Validators.min(0), Validators.maxLength(8),Validators.pattern("^[0-9]*$"), Validators.required]),
      townLogo: new FormControl(''),
      htmlContent: new FormControl({value: town.endText, disabled}, Validators.required),
      kiosk: new FormControl({value: kiosk, disabled}, Validators.required),
      townFormalName: new FormControl({value: town.townFormalName, disabled})
    });
    if (users.length > 0 && this.isAdmin()) {
      this.townForm.addControl('townManagersCheckboxGroup', new FormGroup(checkboxGroup, requireCheckboxesToBeChecked(1, 5)))
    }
  }

  toggleAdminDropdown() {
    this.contentAdminDropdown = !this.contentAdminDropdown;
  }

  closeAlert() {
    this.error = null;
  }

  onFileChange(event) {
    this.logo = Array.from(event.target.files);
    if (this.logo.length > 0) {
      this.isFileSet = true;
      const fileReader = new FileReader();
      fileReader.onload = async e => {
        this.imgURL = this.sanitizer.bypassSecurityTrustUrl(fileReader.result as string);
      };
      fileReader.readAsDataURL(event.target.files[0]);
    }
  }

  onSubmit() {
    const formData = new FormData();
    const kiosk = this.form.kiosk.value == 'yes';

    const town = new Town();
    town.setTowsFields({
      id: this.id, name: this.form.townName.value, apiUsed: kiosk,
      enabled: this.enable, logoUrl: this.form.townLogo.value, endText: this.form.htmlContent.value,
      townCode: this.form.townCode.value, series: this.form.series.value, ticketStart: this.form.ticketStart.value,
      townFormalName: this.form.townFormalName.value
    });
    const townManagerIds = this.users.filter(user => user.role === Role.TM).map(user => user.id);

    formData.append('town', JSON.stringify(town));
    formData.append('townManagerIds', townManagerIds.join(","));
    if (this.logo) {
      formData.append('file', this.logo[0]);
    }

    this.townService.updateTown(formData).subscribe(response => {
        this.town = response;
        this.updateForm(response);
        this.navigationService.setTown(this.town);
        this.navigationService.emitChange(this.town.id);
        this.toastService.show('Town has been successfully updated',
          {classname: 'bg-success text-light', autohide: true, delay: 2000});
      },
      error => {
        this.error = error;
      });
  }

  updateForm(town: ITown) {
    const kiosk = town.apiUsed ? 'yes' : 'no';
    this.townForm.controls.townName.setValue(town.name);
    this.townForm.controls.townCode.setValue(town.townCode);
    this.townForm.controls.htmlContent.setValue(town.endText);
    this.townForm.controls.kiosk.setValue(kiosk);
    this.townForm.controls.townFormalName.setValue(town.townFormalName);
    this.enable = town.enabled;
    this.imgURL = environment.apiUrl + town.logoUrl;
  }

  private initCheckboxGroup(users: User[]) {
    const checkboxGroup: any = {};
    users.forEach(value => {
      checkboxGroup[value.id] = new FormControl(value.role === Role.TM);
      checkboxGroup[value.id].userEnabled = value.enabled;
      checkboxGroup[value.id].valueChanges.subscribe(changedValue => {
        this.toggleUser(value.id, changedValue);
      })
    });
    return checkboxGroup;
  }

  unselectUser(userId: number, event) {
    event.stopPropagation();
    this.form.townManagersCheckboxGroup.get(`${userId}`).setValue(false);
  }

  toggleUser(userId: number, isChecked: boolean) {
    this.users.find(value => value.id === userId).role = isChecked ? Role.TM : Role.LEO;
  }

  get filteredUsers() {
    return this.users.filter(value => value.name.toLocaleLowerCase().includes(this.userFilter.toLocaleLowerCase()))
  }

  goBack() {
    this.routerLocation.back();
  }

  isAdmin() {
    return this.authenticationService.userValue.role === Role.ADMIN;
  }
}
