import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {PlateRecognizeResponse} from "../models/plate-recognize-response";
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class PlateRecognizerResponseService {
  private plateRecognizerResponseSubject: BehaviorSubject<PlateRecognizeResponse>;

  constructor(private http: HttpClient) {
    this.plateRecognizerResponseSubject = new BehaviorSubject<PlateRecognizeResponse>(null);
  }

  setPlateRecognizerResponse(plateRecognizeResponse: PlateRecognizeResponse) {
    this.plateRecognizerResponseSubject.next(plateRecognizeResponse);
  }

  getPlateRecognizerResponse(): PlateRecognizeResponse{
    return this.plateRecognizerResponseSubject.value;
  }

  clearPlateRecognizerResponse() {
    this.plateRecognizerResponseSubject.next(null);
  }

  getRecognizedVehicleData(formData: FormData): Observable<PlateRecognizeResponse> {
    return this.http.post<PlateRecognizeResponse>(`${environment.apiUrl}/violation/processLicensePlate`, formData);
  }

  get2DBarcodeVehicleData(formData: FormData): Observable<PlateRecognizeResponse> {
    return this.http.post<PlateRecognizeResponse>(`${environment.apiUrl}/violation/processBarcode`, formData);
  }

  incrementNumberOfUnsuccessfulBarcodeAttempts() {
    localStorage.setItem('unsuccessfulBarcodeAttempts', `${this.getNumberOfUnsuccessfulBarcodeAttempts()+1}`)
  }

  getNumberOfUnsuccessfulBarcodeAttempts() {
    return +localStorage.getItem('unsuccessfulBarcodeAttempts');
  }

  resetNumberOfUnsuccessfulBarcodeAttempts() {
    localStorage.setItem('unsuccessfulBarcodeAttempts', '0');
  }

  incrementNumberOfUnsuccessfulRecognizerAttempts() {
    localStorage.setItem('unsuccessfulAttempts', `${this.getNumberOfUnsuccessfulRecognizerAttempts()+1}`)
  }

  getNumberOfUnsuccessfulRecognizerAttempts() {
    return +localStorage.getItem('unsuccessfulAttempts');
  }

  resetNumberOfUnsuccessfulRecognizerAttempts() {
    localStorage.setItem('unsuccessfulAttempts', '0');
  }

}
