import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PlateNumberService {
  private plateNumberSubject: BehaviorSubject<string>;

  constructor() {
    this.plateNumberSubject = new BehaviorSubject<string>(null);
  }

  setPlateNumber(plateNumber: string) {
    this.plateNumberSubject.next(plateNumber);
  }

  getPlateNumber(): string {
    return this.plateNumberSubject.value || '';
  }

  clearPlateNumber() {
    this.plateNumberSubject.next(null);
  }
}
