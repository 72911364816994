<div class="modal-header">
  <h4 class="modal-title">Are you sure you want to delete recipient?</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <i class="bi bi-x" aria-hidden="true"></i>
  </button>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-cancel" (click)="activeModal.close('Close click')">Cancel</button>
  <button type="button" class="btn btn-danger create-account" (click)="deleteRecipient()">Delete recipient</button>
</div>
