import {AfterViewChecked, ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {ViolationType} from '../../models/violation-type';
import {requireCheckboxesToBeChecked} from '../../validators/checkboxes.validator';
import {ViolationService} from '../../services/violation.service';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {ComponentCanDeactivate} from '../../guards/component.can.deactivate';
import {PlateNumberService} from '../../services/platenumber.service';
import {PlateRecognizerResponseService} from "../../services/plate-recognizer-response.service";
import {ToastService} from "../../services/toast-service";
import {AuthenticationService} from "../../services/auth/authentication.service";
import {ZoneService} from "../../services/zone.service";
import {getLocation} from "@angular/localize/src/tools/src/translate/source_files/source_file_utils";
import {throwError} from "rxjs";

@Component({
    selector: 'app-submit-violation',
    templateUrl: './submit-violation.component.html',
    styleUrls: ['../../app.component.scss',
        './submit-violation.component.css']
})
export class SubmitViolationComponent implements OnInit, ComponentCanDeactivate, AfterViewChecked {

    stateCodes: string[];
    plateTypes = ['PAN', 'PAS', 'CON', 'MCN', 'UNK', 'Other'];
    plateColors = ['Red', 'Green', 'Other'];
    makeTypes = ['Toyota', 'Honda', 'Nissan', 'Ford', 'Jeep', 'Chevrolet', 'Hyundai', 'Volkswagen', 'Subaru', 'Kia', 'BMW',
      'Mazda', 'Acura', 'Lexus', 'Dodge', 'Tesla', 'Abarth', 'Alfa Romeo', 'Aston Martin', 'Audi', 'Bentley', 'Bugatti',
      'Buick', 'Cadillac', 'Caterham', 'Chrysler', 'Citroen', 'Daewoo', 'Daihatsu', 'Datsun', 'Ducati', 'Ferrari',
      'Fiat', 'Fisker', 'Freightliner', 'Fuso', 'Genesis', 'GMC', 'Harley Davidson', 'Hino', 'Hummer', 'Ice Bear',
      'Indian', 'Infiniti', 'International', 'Isuzu', 'Jaguar', 'Karma', 'Kawasaki', 'Kenworth', 'Lamborghini',
      'Land Rover', 'Lincoln', 'Lotus', 'Maserati', 'McLaren', 'Mercedes Benz', 'Mercury', 'MG', 'Mini', 'Mitsubishi',
      'Oldsmobile', 'Peugeot', 'Polestar', 'Pontiac', 'Porsche', 'Ram', 'Renault', 'Rivian', 'Rolls Royce', 'Saab', 'Saturn',
      'Scion', 'Smart', 'Suzuki', 'Triumph', 'Venom', 'Vespa', 'Vitacci', 'Volvo', 'Yamaha', 'Other'
    ];
    availableExtensions = '.jpg,.jpeg,.png,.mp4,.mov'
    colors: string[];
    violationTypes: ViolationType[];
    violationTypeOtherOptionId: number;
    submitted = false;
    loading = false;
    contentColorDropdown: boolean = false;
    contentStateDropdown: boolean = false;
    contentMakeDropdown: boolean = false;
    showMakeInput: boolean = false;
    showAdditionalFields: boolean = false;
    attachedPhotos;
    colorName: string;
    stateName: string;
    makeName: string;
    plateName: string;
    statesMap: Map<string, string>;
    colorsMap: Map<string, string>;
    showNotesField: boolean = false;
    violationForm: FormGroup;
    error = '';

    townId;

    @ViewChild('stateSelectButton', {static: false}) stateSelectButton: ElementRef;
    @ViewChild('stateContent', {static: false}) stateContent: ElementRef;
    @ViewChild('colorSelectButton', {static: false}) colorSelectButton: ElementRef;
    @ViewChild('colorContent', {static: false}) colorContent: ElementRef;
    @ViewChild('makeContent', {static: false}) makeContent: ElementRef;
    @ViewChild('makeSelectButton', {static: false}) makeSelectButton: ElementRef;

    constructor(private violationService: ViolationService,
                private routerLocation: Location,
                private plateNumberService: PlateNumberService,
                private zoneService: ZoneService,
                private router: Router,
                private route: ActivatedRoute,
                private renderer: Renderer2,
                private changeDetectorRef: ChangeDetectorRef,
                private plateRecognizerResponseService: PlateRecognizerResponseService,
                private toastService: ToastService,
                private authenticationService: AuthenticationService,
                private cdRef: ChangeDetectorRef) {
        this.townId = authenticationService.userValue.townId;
        this.renderer.listen('window', 'click', (e: Event) => {
            const customDropdownElementPropertyNames = [{
                selectButtonName: 'stateSelectButton',
                selectContentName: 'stateContent',
                selectStateName: 'contentStateDropdown'
            }, {
                selectButtonName: 'colorSelectButton',
                selectContentName: 'colorContent',
                selectStateName: 'contentColorDropdown'
            }, {
                selectButtonName: 'makeSelectButton',
                selectContentName: 'makeContent',
                selectStateName: 'contentMakeDropdown'
            }];

            this.handleDropdownOnClick(e, customDropdownElementPropertyNames);
        });
    }

    handleDropdownOnClick(e: Event, customDropdownElementPropertyNames: any[]): void {
        for (let customDropdownElementPropertyName of customDropdownElementPropertyNames) {
            const {selectButtonName, selectContentName, selectStateName} = customDropdownElementPropertyName;
            if (e.target !== this[selectButtonName]?.nativeElement && e.target !== this[selectContentName]?.nativeElement && (<HTMLElement>e.target).parentElement !== this[selectButtonName]?.nativeElement) {
                this[selectStateName] = false;
            }
        }
    }

    ngOnInit(): void {
        this.getViolationFormFieldsData();
        this.changeDetectorRef.detectChanges();
    }

    ngAfterViewChecked(): void {
        this.cdRef.detectChanges();
    }

    get form() {
        return this.violationForm.controls;
    }

    initForm(violationTypes: ViolationType[]) {
        const checkboxGroup = this.initCheckboxGroup(violationTypes);
        const userValue = this.authenticationService.userValue;
        const plateType = this.getPlateType();
        this.colorName = this.getColor();
        this.stateName = this.getState();
        this.makeName = this.makeTypes[0];
        this.showAdditionalFields = this.stateName === 'MA';
        this.violationForm = new FormGroup({
            state: new FormControl(this.stateName, Validators.required),
            plateType: new FormControl(this.showAdditionalFields ? this.plateTypes[0] : this.plateName),
            otherPlateType: new FormControl(this.plateName == 'Other' ? plateType : ''),
            plateColor: new FormControl(this.showAdditionalFields ? this.plateColors[0] : this.colorName),
            licensePlateNumber: new FormControl(this.getPlateNumber(), Validators.required),
            make: new FormControl(this.getMake(), [Validators.required]),
            color: new FormControl(this.colorsMap.get(this.colorName), [Validators.required, Validators.maxLength(3)]),
            location: new FormControl(this.getZoneLocation(), [Validators.required, Validators.maxLength(20)]),
            meterNumber: new FormControl('', Validators.maxLength(255)),
            notes: new FormControl('', Validators.maxLength(50)),
            photoNotes: new FormControl('', Validators.maxLength(50)),
            violationCheckboxGroup: new FormGroup(checkboxGroup, requireCheckboxesToBeChecked()),
            badgeId: new FormControl(userValue.badgeId, Validators.required),
            name: new FormControl(userValue.name, Validators.required),
            model: new FormControl(this.getModel()),
            type: new FormControl(this.getType()),
            time: new FormControl()
        });
        console.log(this.getZoneNumber());
        console.log(this.getZoneLocation());
    }

    getPlateNumber() {
        return this.plateRecognizerResponseService.getPlateRecognizerResponse()?.plate || this.plateNumberService.getPlateNumber().toUpperCase();
    }

    getZoneNumber() {
      return this.zoneService.getZoneNumber();
    }

    getZoneLocation() {
      return this.zoneService.getZoneLocation();
    }

    getMake() {
        let plateRecognizerResponse = this.plateRecognizerResponseService.getPlateRecognizerResponse();
        const make = plateRecognizerResponse ? plateRecognizerResponse.make : this.makeTypes[0];
        if (plateRecognizerResponse) {
            this.makeName = this.makeTypes.filter(value => make.toLowerCase().startsWith(value.toLowerCase()))[0] || 'Other';
            this.showMakeInput = this.makeName === 'Other';
        }
        return make;
    }

  getPlateType() {
    let plateRecognizerResponse = this.plateRecognizerResponseService.getPlateRecognizerResponse();
    let plateType = plateRecognizerResponse ? plateRecognizerResponse.type : this.plateTypes[0];
    if (plateRecognizerResponse) {
      this.plateName = this.plateTypes.filter(value => plateType.toLowerCase().startsWith(value.toLowerCase()))[0] || 'Other';
    } else {
      plateType = this.plateTypes[0];
      this.plateName = this.plateTypes[0];
    }
    return plateType;
  }

    getModel() {
        return this.plateRecognizerResponseService.getPlateRecognizerResponse()?.model || '';
    }

    getState() {
        const stateFromPlateRecognizer = this.plateRecognizerResponseService.getPlateRecognizerResponse()?.state;
        const stateCodeFromPlateRecognizer = stateFromPlateRecognizer ? this.statesMap.get(stateFromPlateRecognizer) : undefined;
        return stateCodeFromPlateRecognizer || this.stateCodes[0];
    }

    getColor() {
        let s = this.plateRecognizerResponseService.getPlateRecognizerResponse()?.color || this.colors[0];
        return s;
    }

    getType() {
        return this.plateRecognizerResponseService.getPlateRecognizerResponse()?.type || '';
    }

    getViolationFormFieldsData() {
        this.violationService.getViolationFormFieldsData(this.townId)
            .subscribe(value => {
                this.statesMap = new Map<string, string>(Object.entries(value.states));
                this.colorsMap = new Map<string, string>(Object.entries(value.colors));
                this.stateCodes = Array.from(this.statesMap.values());
                this.colors = Array.from(this.colorsMap.keys());
                this.violationTypes = value.violationTypes;
                this.violationTypeOtherOptionId = this.violationTypes.filter(this.isOtherViolationTypePresent)[0]?.id;
                this.initForm(this.violationTypes);
            })
    }

    isOtherViolationTypePresent(violationType: ViolationType) {
      return violationType.violationTypeName.toLowerCase() === 'other';
    }

    get isOtherSelected(): boolean {
        const isOtherOptionSelected = this.violationForm.controls.violationCheckboxGroup.get(`${this.violationTypeOtherOptionId}`).value;
        const otherViolationTypeFormControl = this.violationForm.get('violationCheckboxGroup').get('otherViolationType');
        if (isOtherOptionSelected) {
            otherViolationTypeFormControl.setValidators([Validators.required]);
        } else {
            otherViolationTypeFormControl.clearValidators();
        }
        otherViolationTypeFormControl.updateValueAndValidity();
        return isOtherOptionSelected;
    }

    onSubmit() {
        this.error = '';

        this.submitted = true;
        if (this.violationForm.invalid || !this.isAccessoryDataValid()) {
            return;
        }
        this.loading = true;

        const selectedState = this.getValueOrGetOtherOption('state');
        const requestBody = {
            state: selectedState,
            plateType: selectedState !== 'MA' ? '' : this.getValueOrGetOtherOption('plateType'),
            plateColor: selectedState !== 'MA' ? '' : this.form.plateColor.value,
            licensePlateNumber: this.form.licensePlateNumber.value.trim(),
            make: this.form.make.value?.trim() ? this.form.make.value?.trim() : this.makeName,
            color: this.form.color.value.trim(),
            location: this.form.location.value.trim(),
            meterNumber: this.form.meterNumber.value?.trim() ?? '',
            notes: this.form.notes.value?.trim() ?? '',
            violationTypes: this.getSelectedViolationTypes(),
            badgeId: this.form.badgeId.value,
            name: this.form.name.value.trim(),
            model: this.form.model.value?.trim(),
            type: this.form.type.value?.trim(),
            photoNotes: this.form.photoNotes.value?.trim(),
            townId: this.townId,
            startTime: this.buildDateString(this.form.time.value),
            zoneNumber: this.getZoneNumber()
        };

        const formData = new FormData();
        formData.append("violationRecord", JSON.stringify(requestBody));

        let totalFileSize = 0;
        if (this.attachedPhotos) {
            for (let photo of this.attachedPhotos) {
                formData.append("files", photo);
                totalFileSize += photo.size;
            }
        }
        if (totalFileSize > 32000000) { // approximately 32 MB, setup in nginx.conf, add this check to avoid nginx html response, there will be not readable message for user
            this.error = 'Total media size is too large, max total size is 32MB';
            this.loading = false;
            return;
        }

        this.violationService.submitViolation(formData)
            .subscribe(
                value => {
                    this.loading = false;
                    this.violationService.setNextViolationRecord(value);
                    this.router.navigate(['/ticket-preview']);
                },
                error => {
                    this.loading = false;
                    this.error = error;
                }
            );
        this.plateRecognizerResponseService.resetNumberOfUnsuccessfulRecognizerAttempts();
        this.plateRecognizerResponseService.resetNumberOfUnsuccessfulBarcodeAttempts();
    }

    closeAlert() {
        this.error = null;
    }

    onStateChange() {
        const {state, plateType, plateColor, otherPlateType} = this.form;

        if (state.value === 'MA') {
            plateType.setValidators(Validators.required);
            plateType.setValue(this.plateTypes[0]);
            plateColor.setValidators(Validators.required);
            plateColor.setValue(this.plateColors[0]);
            if (plateType.value === 'Other') {
                otherPlateType.setValidators(Validators.required);
            }
        } else if (state.value === 'Other') {
            plateType.clearValidators();
            plateColor.clearValidators();
            otherPlateType.clearValidators();
        } else {
            plateType.clearValidators();
            plateColor.clearValidators();
            otherPlateType.clearValidators();
        }

        plateType.updateValueAndValidity();
        plateColor.updateValueAndValidity();
        otherPlateType.updateValueAndValidity();
    }

    goBack() {
        this.routerLocation.back();
        this.plateRecognizerResponseService.clearPlateRecognizerResponse();
    }

    private getValueOrGetOtherOption(propertyName: string): string {
        let value = this.form[propertyName].value;
        if (value === 'Other') {
            const capitalizedPropertyName = propertyName.charAt(0).toUpperCase() + propertyName.slice(1);
            value = this.form[`other${capitalizedPropertyName}`].value;
        }
        return value ? value.trim() : value;
    }

    private getSelectedViolationTypes() {
        const violationCheckboxGroupValues = this.form.violationCheckboxGroup.value;
        const selectedViolationTypes = [];

        Object.keys(violationCheckboxGroupValues)
            .filter(key => key.match(/\d+/) && violationCheckboxGroupValues[key])
            .map(key => {
                const descriptionForOtherOption = +key === this.violationTypeOtherOptionId
                    ? violationCheckboxGroupValues.otherViolationType.trim()
                    : null;

                selectedViolationTypes.push({id: +key, descriptionForOtherOption});
            });
        return selectedViolationTypes;
    }

    private initCheckboxGroup(violationTypes: ViolationType[]) {
        let checkboxGroup: any = {};
        violationTypes.forEach(value => {
            checkboxGroup[value.id] = new FormControl(false);
            if (this.isOtherViolationTypePresent(value)) {
              const otherViolationType = new FormControl('', Validators.required);
              checkboxGroup = {...checkboxGroup, otherViolationType};
            }
        });
        return checkboxGroup;
    }

    onPlateTypeChange() {
        const {plateType, otherPlateType} = this.form;
        if (plateType.validator && plateType.value === 'Other') {
            otherPlateType.setValidators(Validators.required);
        } else {
            otherPlateType.clearValidators();
        }
        otherPlateType.updateValueAndValidity();
    }

    canDeactivate(): boolean {
        this.plateNumberService.clearPlateNumber();
        this.zoneService.clearZoneNumber();
        this.zoneService.clearZoneLocation();
        return true;
    }

    toggleColorDropdown() {
        this.contentColorDropdown = !this.contentColorDropdown;
    }

    toggleStateDropdown() {
        this.contentStateDropdown = !this.contentStateDropdown;
    }

    toggleMakeDropdown() {
        this.contentMakeDropdown = !this.contentMakeDropdown;
    }

    selectColor(color: string) {
        this.colorName = color;
        this.violationForm.controls.color.setValue(this.colorsMap.get(this.colorName));
        this.contentColorDropdown = false;
    }

    selectMake(make: string) {
        this.showMakeInput = make === 'Other';
        this.makeName = make;
        const makeValue = make === 'Other' ? '' : make;
        this.violationForm.controls.make.setValue(makeValue);
        this.contentMakeDropdown = false;
    }

    selectState(state: string) {
        this.stateName = state;
        this.violationForm.controls.state.setValue(this.stateName);
        this.contentStateDropdown = false;
        this.showAdditionalFields = this.stateName === 'MA';
        this.onStateChange();
    }

    onFileSelected(event: any) {
      if (!this.attachedPhotos) {
        this.attachedPhotos = [];
      }
      event.target.files.forEach(file => {
        this.attachedPhotos.push(file);
      })
    }

    deleteFile(i) {
        this.attachedPhotos.splice(i, 1);
    }

    getFileSizeStr(size: number): string {
      let sizeStr: string = '';
      const sizeKb = size/1024;

      if (sizeKb < 1) {
        sizeStr = size + ' Bytes'
      } else if (sizeKb >= 1 && sizeKb < 1024) {
        sizeStr = sizeKb.toFixed(2) + ' kB'
      } else if (sizeKb >= 1024) {
        sizeStr = (size/1024/1024).toFixed(2) + ' MB'
      }
      return sizeStr;
    }

    showNotes() {
        this.showNotesField = !this.showNotesField;
    }

    isAccessoryDataValid() {
        return !this.attachedPhotos || this.attachedPhotos.length <= 4;
    }

  private titleCaseWord(word: string) {
    if (!word) return word;
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }

  private buildDateString(timePicker: any) {
        let timeString = '';
        if (timePicker) {
          let numHours = this.numToStr(timePicker.hour) == "0" ? "00" : this.numToStr(timePicker.hour);
          let numMinutes = this.numToStr(timePicker.minute) == "0" ? "00" : this.numToStr(timePicker.minute);
            timeString = `${numHours}:${numMinutes}`;
        } else {
            return undefined;
        }
        let date = new Date();
        let dateString = `${this.numToStr(date.getFullYear())}-${this.numToStr(date.getMonth() + 1)}-${this.numToStr(date.getDate())}T`;
        return dateString + timeString;
    }

    private numToStr(str: any) {
        if (str && str.toString().length == 1) {
            return '0' + str;
        }
        return str;
    }

}
