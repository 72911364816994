import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {ViolationService} from "../../../services/violation.service";
import {ToastService} from "../../../services/toast-service";
import {environment} from "../../../../environments/environment";
import {ViolationType} from "../../../models/violation-type";
import {concatMap} from "rxjs/operators";

@Component({
  selector: 'app-violations-list',
  templateUrl: './violations-list.component.html',
  styleUrls: ['../../../app.component.scss', './violations-list.component.scss']
})
export class ViolationsListComponent implements OnInit {

  private routeSubscription: Subscription;
  private modal: NgbModalRef;

  currentPage = 0;
  violations;
  totalElements;
  size;

  townId;
  environment: any;
  error = '';

  violationForm = new FormGroup({});

  constructor(private modalService: NgbModal, private violationService: ViolationService,
              private route: ActivatedRoute, private toastService: ToastService) {
    this.routeSubscription = route.parent.params.subscribe(params => this.townId = params.id);
  }

  openBackDropCustomClass(content) {
    this.modal = this.modalService.open(content, {backdropClass: 'light-blue-backdrop'});
  }

  ngOnInit(): void {
    this.initForm();
    this.loadViolations(0, this.townId).subscribe(value => this.processViolationTypesResponse(value));
    this.environment = environment;
  }

  get form() {
    return this.violationForm.controls;
  }

  closeAlert() {
    this.error = null;
  }

  initForm() {
    this.violationForm = new FormGroup({
      code: new FormControl('', Validators.required),
      fee: new FormControl('',
        [Validators.required, Validators.pattern("^[0-9]*$")]),
      violation: new FormControl('', Validators.required)
    });
  }

  loadViolations(page: number, townId: number) {
    return this.violationService.getViolationTypesByTown(page, townId);
  }

  private processViolationTypesResponse(value: any) {
    this.violations = value.content;
    this.totalElements = value.totalElements;
    this.size = value.size;
  }

  closeModal() {
    this.modal.close();
    this.resetForm();
  }

  resetForm() {
    this.violationForm.reset();
    this.error = '';
  }

  public onPageChange(pageNum: number): void {
    this.currentPage = pageNum - 1;
    this.loadViolations(this.currentPage, this.townId).subscribe(value => this.processViolationTypesResponse(value));
  }

  onSubmit() {
    const formData = new FormData();

    const violation = new ViolationType();
    violation.setViolationFields({
      id: 0, violationTypeCode: this.form.code.value, townId: this.townId,
      violationTypeName: this.form.violation.value, violationTypeFine: this.form.fee.value
    });

    formData.append("violation", JSON.stringify(violation));

    this.violationService.submitViolationType(formData).subscribe(() => {
        this.loadViolations(this.currentPage, this.townId).subscribe(value => this.processViolationTypesResponse(value));
        this.closeModal();
        this.toastService.show('Violation has been successfully created ',
          {classname: 'bg-success text-light', autohide: true, delay: 2000});
      },
      error => {
        this.error = error;
      });
  }

  importCSV(event) {
    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    this.violationService.importViolationsAndFinesFromCSV(formData, this.townId)
      .pipe(
        concatMap(({message}) => {
          this.toastService.show(message, {classname: 'bg-success text-light', autohide: true, delay: 2000});
          return this.violationService.getViolationTypesByTown(0, this.townId);
        })
      )
      .subscribe(value => {
        this.processViolationTypesResponse(value);
      }, error => {
        this.toastService.show(`Violations and Fines have not been imported. Reason: ${error}`,
          {classname: 'bg-danger text-light', autohide: true, delay: 2000});
      });
    event.target.value = '';
  }

  exportCSV() {
    this.violationService.exportViolationsAndFinesAsCSV(this.townId).subscribe(response => {
      const reader = new FileReader();
      const out = new Blob([response], {type: response.type});
      reader.onload = function(e){
        let downloadLink = document.createElement('a');
        downloadLink.href = reader.result as string;
        downloadLink.setAttribute('download', 'violations-and-fees.csv');
        document.body.appendChild(downloadLink);
        downloadLink.click();
        downloadLink.remove();
      };
      reader.readAsDataURL(out);
    });
  }

  changeViolationStatus(id: number, status: boolean) {
    const formData = new FormData();
    formData.append("status", String(status));

    this.violationService.changeViolationStatus(id, formData)
      .pipe(
        concatMap(() => {
          return this.loadViolations(this.currentPage, this.townId);
        })
      )
      .subscribe(value => {
        this.processViolationTypesResponse(value);
        this.toastService.show('Violation status has been successfully updated',
          {classname: 'bg-success text-light', autohide: true, delay: 2000});
      },
      error => {
        this.toastService.show(`Violation status has not been updated. Reason: ${error}`,
          {classname: 'bg-danger text-light', autohide: true, delay: 2000});
      });
  }
}
