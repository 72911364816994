<div class="pop-up__wrapper">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header pop-up__header">
        <h5 class="modal-title pop-up__title">Analyzing License Plate</h5>
        <button type="button" class="close" (click)="clearPlateRecognizerResponse()" data-dismiss="modal"
                aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body pop-up__body">
        <ng-container *ngIf="isLoading">
          <span class="spinner-border spinner-border-sm mr-1"></span>
        </ng-container>
        <ng-container *ngIf="error; else plateRecognizerResponseResult">
          <div class="message">
            <p class="message__text message__text-unsuccessful">{{error}}</p>
            <p class="message__text">Please take another photo or enter license plate number manually</p>
          </div>
          <div class="pop-up__btn">
            <label for="{{takeShotButtonId}}" (click)="onRetakePhotoClick($event)" class="btn-take-photo">Retake photo</label>
          </div>
        </ng-container>
        <ng-template #plateRecognizerResponseResult>
          <ng-container *ngIf="!isLoading">
            <div class="message" *ngIf="!plateRecognizerResponse || !isAttemptAvailable">
              <p class="message__text message__text-unsuccessful">The plate cannot be properly recognized.</p>
              <p class="message__text" *ngIf="!plateRecognizerResponse && isAttemptAvailable">Please take another photo.
                Attempt {{ currentAttempt }}/{{ maxAttempt}}</p>
              <p class="message__text" *ngIf="!plateRecognizerResponse && !isAttemptAvailable">Please enter manually.
                Attempt {{ maxAttempt }}/{{ maxAttempt}}</p>
            </div>
            <div class="pop-up__btn" *ngIf="!plateRecognizerResponse && isAttemptAvailable">
              <label for="{{takeShotButtonId}}" (click)="onRetakePhotoClick($event)" class="btn-take-photo">Retake photo</label>
            </div>
            <div class="message" *ngIf="plateRecognizerResponse">
              <p class="message__text message__text-successful">Recognition succeeded!</p>

              <p class="message__plate-nuber">{{ plateRecognizerResponse.plate | uppercase}}</p>
            </div>
          </ng-container>
        </ng-template>
      </div>
      <div *ngIf="!isLoading" class="modal-footer pop-up__footer">
        <button type="button" class="btn btn-cancel" data-dismiss="modal" (click)="clearPlateRecognizerResponse()">
          Cancel
        </button>
        <button type="button" class="btn btn-submit active" (click)="clearPlateRecognizerResponse()"
                *ngIf="!plateRecognizerResponse || !isAttemptAvailable">
          Enter manually
        </button>
        <button type="button" class="btn btn-submit active" *ngIf="plateRecognizerResponse" (click)="continue()">
          Continue
        </button>
      </div>
    </div>
  </div>
</div>
