import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from '../components/login/login.component';
import {ViolationComponent} from '../components/violation/violation.component';
import {ViolationTicketComponent} from '../components/violation-ticket/violation-ticket.component';
import {CheckViolationComponent} from '../components/check-violation/check-violation.component';
import {SubmitViolationComponent} from '../components/submit-violation/submit-violation.component';
import {AuthGuard} from '../guards/auth.guard';
import {Role} from '../models/role';
import {ExitGuard} from '../guards/exit.guard';
import {DashboardComponent} from "../components/dashboard/dashboard.component";
import {TownAccountComponent} from "../components/dashboard/town-account/town-account.component";
import {TownsListComponent} from "../components/dashboard/towns-list/towns-list.component";
import {UsersListComponent} from '../components/dashboard/users-list/users-list.component';
import {ViolationsListComponent} from '../components/dashboard/violations-list/violations-list.component';
import {TicketsListComponent} from '../components/dashboard/tickets-list/tickets-list.component';
import {UserAccountComponent} from '../components/dashboard/user-account/user-account.component';
import {ViolationDetailsComponent} from '../components/dashboard/violation-details/violation-details.component';
import {TicketDetailsComponent} from '../components/dashboard/ticket-details/ticket-details.component';
import {TownComponent} from "../components/dashboard/town/town.component";
import {DashboardAuthGuard} from "../guards/dashboard.auth.guard";
import {EmailManagementComponent} from "../components/dashboard/email-management/email-management.component";


const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'violation',
    component: ViolationComponent,
    canActivate: [AuthGuard],
    data: {roles: [Role.PEA, Role.LEO]},
    children: [
      {path: '', redirectTo: 'check', pathMatch: 'full'},
      {path: 'check', component: CheckViolationComponent},
      {path: 'submit', component: SubmitViolationComponent, canDeactivate: [ExitGuard]}
    ]
  },
  {
    path: '',
    redirectTo: 'violation',
    pathMatch: 'full'
  },
  {
    path: 'ticket-preview',
    component: ViolationTicketComponent,
    canActivate: [AuthGuard],
    canDeactivate: [ExitGuard],
    data: {roles: [Role.PEA, Role.LEO]},
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [DashboardAuthGuard],
    data: {roles: [Role.ADMIN, Role.TM]},
    children: [
      {path: '', redirectTo: 'towns', pathMatch: 'full'},
      {
        path: 'towns',
        component: TownsListComponent,
        canActivate: [DashboardAuthGuard],
        data: {roles: [Role.ADMIN]},
      },
      {
        path: 'towns/:id',
        component: TownComponent,
        canActivate: [DashboardAuthGuard],
        data: {roles: [Role.ADMIN, Role.TM]},
        children: [
          {
            path: '', redirectTo: 'users', pathMatch: 'full'
          },
          {
            path: 'users',
            component: UsersListComponent,
            canActivate: [DashboardAuthGuard],
            data: {roles: [Role.ADMIN, Role.TM]},
          },
          {
            path: 'edit',
            component: TownAccountComponent,
            canActivate: [DashboardAuthGuard],
            data: {roles: [Role.ADMIN, Role.TM]},
          },
          {
            path: 'users/:id',
            component: UserAccountComponent,
            canActivate: [DashboardAuthGuard],
            data: {roles: [Role.ADMIN, Role.TM]},
          },
          {
            path: 'violations',
            component: ViolationsListComponent,
            canActivate: [DashboardAuthGuard],
            data: {roles: [Role.ADMIN, Role.TM]},
          },
          {
            path: 'violations/:id',
            component: ViolationDetailsComponent,
            canActivate: [DashboardAuthGuard],
            data: {roles: [Role.ADMIN, Role.TM]},
          },
          {
            path: 'tickets',
            component: TicketsListComponent,
            canActivate: [DashboardAuthGuard],
            data: {roles: [Role.ADMIN, Role.TM]},
          },
          {
            path: 'tickets/:id',
            component: TicketDetailsComponent,
            canActivate: [DashboardAuthGuard],
            data: {roles: [Role.ADMIN, Role.TM]},
          },
          {
            path: 'email-management',
            component: EmailManagementComponent,
            canActivate: [DashboardAuthGuard],
            data: {roles: [Role.ADMIN]}
          }
        ]
      },
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
