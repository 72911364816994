<div class="modal-header">
  <h4 class="modal-title">Edit Daily Digest Recipient</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <i class="bi bi-x" aria-hidden="true"></i>
  </button>
</div>
<div class="modal-body">
  <ngb-alert *ngIf="error" (close)="closeAlert()" [type]="'danger'">{{ error }}</ngb-alert>
  <form class="add-new-user" [formGroup]="dailyDigestRecipientEditForm">
    <div class="form-group row">
      <div class="col-12 col-md-12">
        <label class="is-required"
               for="email">Email</label>
        <input type="email"
               autocomplete="off"
               placeholder="Email"
               name="email"
               formControlName="email"
               class="form-control"
               id="email"
               [ngClass]="{ 'is-invalid': form.email.errors }">
        <div *ngIf="form.email.errors" class="required">
          <div *ngIf="form.email.errors.required">This field is required</div>
          <div *ngIf="form.email.errors.maxlength">This field must be less than 255 characters</div>
          <div *ngIf="form.email.errors.pattern">Entered value doesn't correspond to email pattern</div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-cancel" (click)="activeModal.dismiss('Cancel click')">Cancel</button>
  <button [disabled]="dailyDigestRecipientEditForm.invalid"
          [class.active]="!dailyDigestRecipientEditForm.invalid"
          type="button" class="btn btn-dark create-account" (click)="onSubmit()">Edit recipient
  </button>
</div>
