<div class="container violations">
  <div class="violations__header row no-gutters justify-content-between">
    <div class="violations-add">
      <span class="violations-add__title">Violations & Fees <span class="violations-add__count">({{ totalElements }})</span></span>
      <button type="button" class="btn violations-add__btn"
              (click)="openBackDropCustomClass(content)">
        <i class="bi bi-plus violations-add__btn-icon"></i>
        <span class="violations-add__btn-label">Add new violation</span>
      </button>
    </div>
    <div class="row justify-content-between">
      <div class="import">
        <button class="btn import__btn">
            <span class="btn btn-dark file-input-custom">
            <input type="file" (change)="importCSV($event)" accept=".csv"/>
            Import violations
          </span>
        </button>
      </div>
      <div class="export">
        <button type="button" class="btn export__btn" (click)="exportCSV()">
          <span class="btn btn-dark file-input-custom">Export violations</span>
        </button>
      </div>
    </div>
  </div>

  <div class="table">
    <ul class="table__list">
      <li class="table__header d-none d-md-block">
        <ul class="header__list">
          <li class="header__item header__item-code">Violation code</li>
          <li class="header__item header__item-title">Violation</li>
          <li class="header__item header__item-fee">Fee</li>
          <li class="header__item header__item-status">Status</li>
        </ul>
      </li>
      <li class="table__item" *ngFor="let violation of violations; index as i">
        <ul class="info">
          <li class="info__item info__item-code">
            <span class="info__field-name d-md-none">Violation code</span>
            <a [routerLink]="[violation.id]">{{ violation.violationTypeCode }}</a>
          </li>
          <li class="info__item info__item-title">
            <span class="info__field-name d-md-none">Violation</span>
            <span>{{violation.violationTypeName}}</span>
          </li>
          <li class="info__item info__item-fee">
            <span class="info__field-name d-md-none">Fee</span>
            <span>{{violation.violationTypeFine}}</span>
          </li>
          <li class="info__item info__item-enable">
            <span class="info__field-name d-md-none">Status</span>
            <span class="custom-control-span custom-control-span-desktop">{{violation.enabled? 'Enable' : 'Disable'}}</span>
            <span class="custom-control custom-switch">
              <input (click)="changeViolationStatus(violation.id, !violation.enabled)" [checked]="violation.enabled" type="checkbox" class="custom-control-input" id="{{violation.id}}">
              <label class="custom-control-label" for="{{violation.id}}"></label>
            </span>
            <span class="custom-control-span custom-control-span-mobile">{{violation.enabled? 'Enable' : 'Disable'}}</span>
          </li>
        </ul>
      </li>
    </ul>
  </div>

  <div class="table__pagination">
    <ngb-pagination class="d-flex justify-content-end" (pageChange)="onPageChange($event)" [collectionSize]="totalElements" [pageSize]="size" [(page)]="currentPage" aria-label="Town pagination">
      <ng-template ngbPaginationPrevious>Prev</ng-template>
      <ng-template ngbPaginationNext>Next</ng-template>
    </ngb-pagination>
  </div>

  <app-toasts aria-live="polite" aria-atomic="true"></app-toasts>

  <ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title">New Violation</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <i class="bi bi-x" aria-hidden="true"></i>
      </button>
    </div>
    <div class="modal-body">
      <ngb-alert *ngIf="error" (close)="closeAlert()" [type]="'danger'">{{ error }}</ngb-alert>
      <form class="add-new-user" [formGroup]="violationForm">
        <div class="form-group row">
          <div class="col-12 col-md-6">
            <label class="is-required"
                   for="code">Violation code</label>
            <input type="text"
                   placeholder="Violation code"
                   name="code"
                   formControlName="code"
                   class="form-control"
                   id="code">
          </div>
          <div class="col-12 col-md-6">
            <label class="is-required"
                   for="fee">Fee</label>
            <input type="text"
                   placeholder="Fee"
                   name="fee"
                   formControlName="fee"
                   class="form-control"
                   id="fee">
          </div>
        </div>
        <div class="form-group row">
          <div class="col-12 add-new-violation__textarea">
            <label class="is-required">Violation</label>
            <textarea placeholder="Violation"
            formControlName="violation" ></textarea>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-cancel" (click)="modal.close('Close click')">Cancel</button>
      <button [disabled]="violationForm.invalid" [class.active]="!violationForm.invalid"
        type="button" class="btn btn-dark create-account" (click)="onSubmit()">Create violation</button>
    </div>
  </ng-template>
</div>
