import {Injectable} from "@angular/core";
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) {
  }

  getAllUsersInTown(townId: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}/users/getAllInTown/${townId}?disablePaging=true`);
  }

  getAllUsersInTownPageable(townId: number, page: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}/users/getAllInTown/${townId}?page=${page}`);
  }

  getUserById(userId: number) {
    return this.http.get(`${environment.apiUrl}/users/get/${userId}`);
  }

  createUser(requestBody: object): Observable<any> {
    return this.http.post(`${environment.apiUrl}/users/save`, requestBody);
  }

  updateUser(requestBody: object): Observable<any> {
    return this.http.put(`${environment.apiUrl}/users/save`, requestBody);
  }

  changeUserStatus(userId: number, requestBody: object): Observable<any> {
    return this.http.put(`${environment.apiUrl}/users/status/${userId}`, requestBody);
  }

  printChart(townId: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}/users/print/${townId}`, {responseType: 'blob'});
  }

}
