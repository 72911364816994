import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PlateRecognizerResponseService} from "../../../services/plate-recognizer-response.service";

@Component({
  selector: 'app-plate-recognizer-response-pop-up',
  templateUrl: './plate-recognizer-response-pop-up.component.html',
  styleUrls: ['../../../app.component.scss',
    './plate-recognizer-response-pop-up.component.css']
})
export class PlateRecognizerResponsePopUpComponent {

  @Output() closePopUpEvent = new EventEmitter();
  @Output() continueEvent = new EventEmitter();
  @Output() retakePhotoEvent = new EventEmitter();
  @Input() isLoading;
  @Input() error;
  @Input() isAllowedToUsePCWebCam;
  maxAttempt: number = 3;

  constructor(private plateRecognizerResponseService: PlateRecognizerResponseService) {
  }

  clearPlateRecognizerResponse() {
    this.closePopUpEvent.emit(null);
  }

  continue() {
    this.continueEvent.emit(null);
    this.clearPlateRecognizerResponse();
  }

  get plateRecognizerResponse() {
    return this.plateRecognizerResponseService.getPlateRecognizerResponse();
  }


  get currentAttempt() {
    return this.plateRecognizerResponseService.getNumberOfUnsuccessfulRecognizerAttempts();
  }

  get isAttemptAvailable() {
    return this.currentAttempt < this.maxAttempt;
  }

  get takeShotButtonId() {
    return this.isAllowedToUsePCWebCam ? 'buttonTakePhotoLeo' : 'buttonTakePhoto';
  }

  onRetakePhotoClick(e) {
    if (this.isAllowedToUsePCWebCam) {
      e.preventDefault();
      this.retakePhotoEvent.emit(null);
    }
  }
}
