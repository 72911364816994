import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {EmailManagementService} from "../../../../../services/email-management.service";

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['../../../../../app.component.scss','./confirmation-dialog.component.css']
})
export class ConfirmationDialogComponent implements OnInit {

  @Input() recipientId;
  @Output() resultEvent = new EventEmitter();

  constructor(public activeModal: NgbActiveModal, private emailManagementService: EmailManagementService) {
  }

  ngOnInit(): void {
  }

  deleteRecipient() {
    this.emailManagementService.deleteRecipient(this.recipientId).subscribe(() => {
      this.activeModal.dismiss();
      this.resultEvent.emit(true);
    }, () => {
      this.resultEvent.emit(false);
    });
  }
}
