import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {NavigationService} from "../../../services/navigation.service";

@Component({
  selector: 'app-town',
  templateUrl: './town.component.html',
  styleUrls: ['./town.component.css']
})
export class TownComponent implements OnInit {

  constructor(private route: ActivatedRoute,
              private navigationService: NavigationService) { }

  ngOnInit(): void {
    this.route.params.subscribe(value => {
      this.navigationService.emitChange(value['id']);
    });
  }

}
