import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {map, tap} from 'rxjs/operators';
import * as jwt_decode from 'jwt-decode';
import {User} from '../../models/user';
import {environment} from '../../../environments/environment';
import {Role} from '../../models/role';
import {error} from "protractor";

@Injectable({providedIn: 'root'})
export class AuthenticationService {
  private userSubject: BehaviorSubject<User>;
  public user: Observable<User>;

  constructor(
    private router: Router,
    private http: HttpClient
  ) {
    this.userSubject = new BehaviorSubject<User>(this.decodeUser(JSON.parse(localStorage.getItem('token'))));
    this.user = this.userSubject.asObservable();
  }

  public get userValue(): User {
    return this.userSubject.value;
  }

  login(username: string, password: string) {
    return this.http.post<any>(`${environment.apiUrl}/auth/signin`, {username, password}, {observe: 'response'})
      .pipe(tap(data => {
        const authorization = data.headers.get('Authorization').replace('Bearer ', '');
        const refresh = data.headers.get('Refresh-Token');
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('token', JSON.stringify(authorization));
        localStorage.setItem('unsuccessfulAttempts', '0');
        localStorage.setItem('refresh_token', refresh);
        const user = this.decodeUser(authorization);
        this.userSubject.next(user);
      }));
  }

  refresh(status?) {
    let refreshToken = localStorage.getItem('refresh_token');
    return this.http.post<any>(`${environment.apiUrl}/auth/refreshtoken`, {refreshToken: refreshToken}, {observe: 'response'})
      .pipe(map(data => {
        const authorization = data.headers.get('Authorization').replace('Bearer ', '');
        localStorage.setItem('token', JSON.stringify(authorization));
        const user = this.decodeUser(authorization);
        this.userSubject.next(user);
        return true;
      })).toPromise().catch(() => {
          this.logout(status);
          return false;
        }
      );
  }

  private decodeUser(authorizationToken: string): User {
    if (!authorizationToken) {
      return null;
    }
    const decodedJwt = jwt_decode(authorizationToken);
    let token = authorizationToken;
    let role = Role[decodedJwt.roles[0]];
    let username = decodedJwt.sub;
    // expiration time is stored in seconds. we have to multiply by 1000 to convert it to ms.
    let expiresAt = decodedJwt.exp * 1000;
    let name = decodedJwt.fullName;
    let badgeId = decodedJwt.badgeId;
    let id = decodedJwt.id;
    let technology = decodedJwt.technology;
    let townId = decodedJwt.townId;

    const user = new User();
    user.setUserFields({
      username: username, name: name, role: role, expiresAt: expiresAt,
      id: id, token: token, badgeId: badgeId, technology: technology, townId: townId
    });
    return user;
  }

  logout(status?) {
    localStorage.clear();
    this.userSubject.next(null);
    this.router.navigate(['/login'], {queryParams: {status}});
  }
}
