import {Component, Input, OnInit} from '@angular/core';
import {EmailManagementService} from "../../../services/email-management.service";
import {DailyDigestType} from "../../../models/daily-digest-type";
import {DailyDigestRecipient} from "../../../models/daily-digest-recipient";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {ToastService} from "../../../services/toast-service";
import {EMPTY, forkJoin} from "rxjs";
import {concatMap, switchMap} from "rxjs/operators";
import {ConfirmationDialogComponent} from "./modal/confirmation-dialog/confirmation-dialog.component";
import {EditRecipientDialogComponent} from "./modal/edit-recipient-dialog/edit-recipient-dialog.component";

@Component({
  selector: 'app-email-management',
  templateUrl: './email-management.component.html',
  styleUrls: ['../../../app.component.scss', './email-management.component.scss']
})
export class EmailManagementComponent implements OnInit {

  dailyDigestTypes: DailyDigestType[];

  townId: number;
  dailyDigestRecipients: DailyDigestRecipient[];
  private modal: NgbModalRef;
  currentPage = 0;
  totalElements;
  size;
  error = '';
  dailyDigestRecipientForm: FormGroup;
  @Input() public recipientId;

  constructor(private route: ActivatedRoute,
              private emailManagementService: EmailManagementService,
              private modalService: NgbModal,
              private toastService: ToastService) {
    route.parent.params.subscribe(params => this.townId = params.id);
  }

  ngOnInit(): void {
    forkJoin({
      types: this.emailManagementService.getAllDailyDigestTypes(this.townId),
      recipients: this.emailManagementService.getAllDailyDigestRecipientsByTownId(this.townId, 0)
    }).subscribe(({types, recipients}) => {
      this.dailyDigestTypes = types;
      this.readRecipientsFromResponse(recipients);
      this.initForm(this.dailyDigestTypes);
    });
  }

  initForm(dailyDigestTypes: DailyDigestType[]) {
    let checkboxGroup: any = {};
    dailyDigestTypes.forEach(value => {
      checkboxGroup[value.id] = new FormControl(false);
    });
    this.dailyDigestRecipientForm = new FormGroup({
      email: new FormControl('', [Validators.maxLength(255),Validators.pattern('(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))'), Validators.required]),
      dailyDigestTypeCheckboxGroup: new FormGroup(checkboxGroup),
    });
  }

  private loadRecipients(page: number) {
    this.emailManagementService.getAllDailyDigestRecipientsByTownId(this.townId, page).subscribe(value => {
      this.readRecipientsFromResponse(value);
    });
  }

  openBackDropCustomClass(content) {
    this.modal = this.modalService.open(content, {backdropClass: 'light-blue-backdrop'});
  }

  toggleDailyDigestTypeForRecipient(recipientId: number, typeId: number, status: boolean) {
    const formData = new FormData();
    formData.append("status", String(status));
    this.emailManagementService.changeDailyDigestTypeStatusForRecipient(recipientId, typeId, formData).subscribe(() => {
        this.toastService.show('Daily Digest Configuration has been successfully updated',
          {classname: 'bg-success text-light', autohide: true, delay: 2000});
      },
      error => {
        this.toastService.show(error,
          {classname: 'bg-danger text-light', autohide: true, delay: 2000});
      });
  }

  public onPageChange(pageNum: number): void {
    this.currentPage = pageNum - 1;
    this.loadRecipients(this.currentPage);
  }

  closeAlert() {
    this.error = null;
  }

  closeModal() {
    this.modal.close();
    this.resetForm();
  }

  resetForm() {
    this.dailyDigestRecipientForm.reset();
    this.error = '';
  }

  onSubmit() {
    const dailyDigestRecipient = {
      email: this.form.email.value,
      townId: this.townId,
      dailyDigestTypes: this.getDailyDigestTypesForRecipient()
    };
    const formData = new FormData();
    formData.append("recipient", JSON.stringify(dailyDigestRecipient));
    this.emailManagementService.createRecipient(formData).pipe(
      concatMap(() => this.emailManagementService.getAllDailyDigestRecipientsByTownId(this.townId, this.currentPage - 1))
    ).subscribe(value => {
      this.readRecipientsFromResponse(value);
      this.closeModal()
    }, error => {
      this.error = error;
    });
  }

  readRecipientsFromResponse(value: any) {
    this.dailyDigestRecipients = value.content;
    this.totalElements = value.totalElements;
    this.size = value.size;
  }

  private getDailyDigestTypesForRecipient() {
    return Object.entries(this.form.dailyDigestTypeCheckboxGroup.value).map(([key, value]) => {
      return {id: +key, enabled: value};
    });
  }

  get form() {
    return this.dailyDigestRecipientForm.controls;
  }


  openDeletionConfirmationDialog(recipientId: number) {
    const confirmationDialog = this.modalService.open(ConfirmationDialogComponent, {backdropClass: 'light-blue-backdrop'});
    confirmationDialog.componentInstance.recipientId = recipientId;
    confirmationDialog.componentInstance.resultEvent.pipe(
      switchMap(isDeletionSuccessful => {
        if (isDeletionSuccessful) {
          this.toastService.show('Recipient has been successfully deleted',
            {classname: 'bg-success text-light', autohide: true, delay: 2000});
          return this.emailManagementService.getAllDailyDigestRecipientsByTownId(this.townId, this.currentPage - 1);
        } else {
          this.toastService.show('Could not delete recipient',
            {classname: 'bg-danger text-light', autohide: true, delay: 2000});
          return EMPTY;
        }
      })
    ).subscribe(value => {
      this.readRecipientsFromResponse(value);
    });
  }

  openRecipientEditDialog(recipient: DailyDigestRecipient) {
    const recipientEditDialog = this.modalService.open(EditRecipientDialogComponent, {backdropClass: 'light-blue-backdrop'});
    recipientEditDialog.componentInstance.recipient = recipient;
    recipientEditDialog.componentInstance.resultEvent.pipe(
      switchMap(value => {
        if (value) {
          this.toastService.show('Email has been successfully changed', {classname: 'bg-success text-light', autohide: true, delay: 2000});
          return this.emailManagementService.getAllDailyDigestRecipientsByTownId(this.townId, this.currentPage-1);
        } else {
          this.toastService.show('Could not change email', {classname: 'bg-danger text-light', autohide: true, delay: 2000});
          return EMPTY;
        }
      })
    ).subscribe(value => {
       this.readRecipientsFromResponse(value);
    })
  }
}
