import { Component, OnInit } from '@angular/core';
import { ViolationService } from "../../../services/violation.service";
import { forkJoin, Subscription } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { FormControl, FormGroup } from "@angular/forms";
import { ViolationRecord } from "../../../models/violation-record";
import {Location} from "@angular/common";
import {ViolationType} from "../../../models/violation-type";

@Component({
  selector: 'app-ticket-details',
  templateUrl: './ticket-details.component.html',
  styleUrls: ['../../../app.component.scss', './ticket-details.component.scss']
})
export class TicketDetailsComponent implements OnInit {

  private routeSubscription: Subscription;

  violationRecord: ViolationRecord;
  violationId;
  townId;

  violationRecordForm: FormGroup;

  constructor(private route: ActivatedRoute,
              private violationService: ViolationService, private routerLocation: Location) {
    this.routeSubscription = route.params.subscribe(params => this.violationId = params.id);
    this.routeSubscription = route.parent.params.subscribe(params => this.townId = params.id);
  }

  ngOnInit(): void {
    let violationRecordById = this.violationService.getViolationRecordById(this.violationId);
    forkJoin([violationRecordById]).subscribe(results => {
      this.violationRecord = results[0];
      this.initForm(this.violationRecord);
    });
  }

  initForm(violationRecord: ViolationRecord) {
    this.violationRecordForm = new FormGroup({
      serialNumber: new FormControl(violationRecord.serialNumber),
      plateNumber: new FormControl(violationRecord.licensePlateNumber),
      date: new FormControl(violationRecord.date),
      time: new FormControl(violationRecord.time),
      startTime: new FormControl(violationRecord.startTime),
      state: new FormControl(violationRecord.state),
      plateType: new FormControl(violationRecord.plateType),
      plateColor: new FormControl(violationRecord.plateColor),
      make: new FormControl(violationRecord.make),
      color: new FormControl(violationRecord.color),
      location: new FormControl(violationRecord.location),
      meterNumber: new FormControl(violationRecord.meterNumber),
      notes: new FormControl(violationRecord.notes),
      additionalNotes: new FormControl(violationRecord.photoNotes),
      badgeId: new FormControl(violationRecord.badgeId),
      name: new FormControl(violationRecord.name)
    });
  }

  goBack() {
    this.routerLocation.back();
  }

  exportAccessoryData() {
    this.violationService.downloadViolationAccessoryData(this.violationId).subscribe(value => {
      const fileName = `${this.violationRecord.serialNumber}.zip`;
      const reader = new FileReader();
      const out = new Blob([value], {type: value.type});
      reader.onload = function(e){
        let downloadLink = document.createElement('a');
        downloadLink.href = reader.result as string;
        downloadLink.setAttribute('download', fileName);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        downloadLink.remove();
      };
      reader.readAsDataURL(out);
    })
  }

  isDescriptionForOtherViolationAvailable(violation: ViolationType) {
    return violation.violationTypeName.toLowerCase() === 'other' && violation.descriptionForOtherOption;
  }
}
